import { ButtonsContainer } from "./styles";
import editCircle from "../iconsFolder/edit-circle.svg";
import downloadIcon from "../iconsFolder/DownloadIcon.svg";
import loadProducts from "../iconsFolder/LoadProducts.svg";
import spinner from "../iconsFolder/Spinner.gif";
import { useState } from "react";

export const OptionsButtons = (props) => {
  const [isNotTaskFilter] = useState(!props.taskFilter);

  return (
    <>
      {!props.hideButtons && (
        <ButtonsContainer>
          <button className="circle" onClick={() => props.editProducts()}>
            <img src={editCircle} alt="Edit products" />
          </button>
          {isNotTaskFilter && (
            <button className="circle" onClick={() => props.loadMoreProducts()}>
              <img src={loadProducts} alt="Load more products" />
            </button>
          )}
          {props.loadingExportables ? (
            <button className="circle">
              <img src={spinner} alt="Download products" />
            </button>
          ) : (
            isNotTaskFilter && (
              <button className="circle">
                <img
                  src={downloadIcon}
                  alt="Download products"
                  onClick={() => props.download()}
                />
              </button>
            )
          )}
          {isNotTaskFilter && (
            <button
              className="export-button"
              onClick={() => props.exportProducts()}
            >
              Exportar
            </button>
          )}
        </ButtonsContainer>
      )}
      {props.csvDownload && (
        <ButtonsContainer>
          <button className="circle">
            <img
              src={downloadIcon}
              alt="Download products"
              onClick={() => props.download()}
            />
          </button>
        </ButtonsContainer>
      )}
    </>
  );
};
