import styled from "styled-components";

export const CheckboxCustomContainer = styled.div`
  input {
    display: none;

    &:checked + label {
      &::before {
        background-image: url(${(props) => props.checked});
      }
    }
  }

  label {
    color: ${(props) => props.color};

    &::before {
      content: "";
      display: block;
      width: ${(props) => (props.size ? props.size : "13")}px;
      height: ${(props) => (props.size ? props.size : "13")}px;
      background-image: url(${(props) => props.unchecked});
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  * {
    box-sizing: border-box;
  }
`;
