export function formatDate(date) {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  return `${year}/${month}/${day}`;
}

export const datesOption = (setDatesRange) => {
  const today = new Date();
  let endDate = formatDate(today);
  const temp = [
    {
      id: 0,
      name: "Rango de fechas",
    },
    {
      id: 1,
      name: "Hoy",
      function: () => setDatesRange({ startDate: formatDate(today), endDate }),
    },
    {
      id: 2,
      name: "Ayer",
      function: () =>
        setDatesRange({
          startDate: formatDate(new Date(today.setDate(today.getDate() - 1))),
          endDate: formatDate(new Date(today.setDate(today.getDate() - 1))),
        }),
    },
    {
      id: 3,
      name: "Esta semana",
      function: () =>
        setDatesRange({
          startDate: formatDate(
            new Date(today.setDate(today.getDate() - today.getDay()))
          ),
          endDate,
        }),
    },
    {
      id: 4,
      name: "La semana pasada",
      function: () =>
        setDatesRange({
          startDate: formatDate(
            new Date(today.setDate(today.getDate() - today.getDay() - 7))
          ),
          endDate: formatDate(
            new Date(today.setDate(today.getDate() - today.getDay() + 7))
          ),
        }),
    },
  ];
  return temp;
};
