import { Header } from "./BillingComponents/Header";
import { Table } from "./BillingComponents/Table";
import { Container } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import { getProducts, billProducts, onLoad, validateProducts } from "./utils";
import Loading from "../../../components/loading";
import GenericModal from "../../../components/GenericModal";
import sucess from "../../../assets/IconComponents/sucessModal.svg";
import errorModal from "../../../assets/IconComponents/errorModal.svg";

export const Billing = () => {
  const [billingArticles, setBillingArticles] = useState([]);
  const [retailers, setRetailers] = useState({});
  const [upcList, setUpcList] = useState("");
  const [principaProducts, setPrincipalProducts] = useState([]);
  const [filters, setFilters] = useState({ retailers: [], services: [] });
  const [productsToBilling, setProductsToBilling] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [imageAlert, setImageAlert] = useState(sucess);
  const [file, setFile] = useState(null);
  const [showValidateBilling, setShowValidateBilling] = useState(false);

  const loadProducts = async (upcList) => {
    if (upcList.length) {
      const { finalList, retailers } = await getProducts(upcList);
      setPrincipalProducts(finalList);
      setBillingArticles(finalList);
      setRetailers(retailers);
    } else {
      setPrincipalProducts([]);
      setBillingArticles([]);
      setRetailers({});
    }
    setFilters({ retailers: [], services: [] });
    setProductsToBilling([]);
    setLoading(false);
  };

  //useEffect Section--------------------------------------------
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    await loadProducts(upcList);
  }, [upcList]);

  useEffect(() => {
    let tempList = principaProducts.slice();
    if (filters.retailers.length) {
      tempList = tempList.filter((prod) =>
        filters.retailers.includes(prod.retailer + "")
      );
    }
    if (filters.services.length) {
      tempList = tempList.filter((prod) =>
        filters.services.some((flt) =>
          Object.keys(prod.services || {}).includes(flt)
        )
      );
    }

    setBillingArticles(tempList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnChange = (event) => {
    const [file] = event.target.files;
    if (file) {
      setFile(file);
      setShowValidateBilling(true);
    }
  };

  return (
    <Container>
      <Header
        setUpcList={setUpcList}
        retailers={retailers}
        filters={filters}
        setFilters={setFilters}
        billProducts={async (bill) => {
          setLoading(true);
          await billProducts(
            bill,
            productsToBilling,
            filters,
            setLoading,
            setShowModal,
            setMessage,
            setImageAlert
          );
          await loadProducts(upcList);
        }}
        productsToBilling={productsToBilling}
        handleOnChange={handleOnChange}
        setFile={setFile}
      />
      {loading ? (
        <Loading />
      ) : (
        <Table
          filters={filters}
          retailers={retailers}
          articlesList={billingArticles}
          productsToBilling={productsToBilling}
          setProductsToBilling={setProductsToBilling}
        />
      )}
      {showModal && (
        <GenericModal
          img={imageAlert}
          message={message}
          close={() => setShowModal(false)}
        />
      )}
      {showValidateBilling && (
        <GenericModal
          message="¿Continuar con facturación?"
          button1={{
            name: "Aceptar",
            action: () => {
              try {
                setLoading(true);
                const reader = new FileReader();
                const upcs = [];
                const billingProducts = [];
                let upcList = "";
                reader.onload = async (evt) => {
                  await onLoad(evt, upcs, billingProducts);
                  upcList = upcs.join(",");
                  const { finalList } = await getProducts(upcList);
                  billingProducts.forEach((prod) => {
                    if (!prod.articleId) {
                      finalList.forEach((item) => {
                        if (
                          item.upc === prod.upc &&
                          item.orderId === prod.orderId
                        ) {
                          prod["articleId"] = item.articleId;
                        }
                      });
                    }
                  });
                  const validateMessage = validateProducts(billingProducts);
                  if (validateMessage.length) {
                    setMessage(validateMessage);
                    setImageAlert(errorModal);
                    setShowModal(true);
                  } else {
                    await billProducts(
                      true,
                      billingProducts,
                      {},
                      setLoading,
                      setShowModal,
                      setMessage,
                      setImageAlert
                    );
                    const { finalList, retailers } = await getProducts(upcList);
                    setPrincipalProducts(finalList);
                    setBillingArticles(finalList);
                    setRetailers(retailers);
                  }
                };
                reader.readAsBinaryString(file);
                setShowValidateBilling(false);
              } catch (error) {
                setLoading(false);
              }
            },
          }}
          button2={{
            name: "Cancelar",
            action: () => {
              setShowValidateBilling(false);
              setFile(null);
            },
          }}
        />
      )}
    </Container>
  );
};
