import React, { useState, useEffect } from "react";
import {
  CustomSelect,
  CheckBox,
  TableResizable,
} from "contentoh-components-library";
import { Summarize } from "@mui/icons-material";
import { Container } from "./styles.js";
import { getAcquiredProducts } from "../../_utils/data";
import { getStatusComponent, getUserComponent, getReport } from "./utils.js";
import { FilterInput } from "../../components/filterInput";

export default function Acquired() {
  const [tableContent, setTableContent] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFirstExecution, setIsFirstExecution] = useState(true);

  const [filters, setFilters] = useState({
    upc: [],
    providers: [],
    category: [],
  });
  const [tableHeaders] = useState([
    "SKU",
    "Nombre",
    "Modelo",
    "Proveedor",
    "Departamento",
    "Flujo",
    "Estatus",
    "Orden",
    "Adquirido por",
  ]);

  const stage = {
    seller: "Pagar Vendor/Seller",
    retailer: "Paga THD",
    audit: "Auditoría Content-oh!",
  };

  const setRows = (data) =>
    data.map((article) => {
      return {
        key: article.articleId,
        object: article,
        columns: [
          article.sku ?? "Sin Asignar",
          article.name,
          article.model,
          article.companyName,
          article.category?.replace(/\|/g, " / "),
          stage[article.stage],
          getStatusComponent(article.status),
          article.orderId ?? "N/A",
          getUserComponent(article),
        ],
      };
    });

  useEffect(() => {
    const params = { query: { filters } };
    const controller = new AbortController();
    getAcquiredProducts(params).then((res) => {
      const { data, categories, providers } = JSON.parse(res.data.body);
      let articles = Object.values(data);
      if (isFirstExecution) {
        setProvidersList(providers ?? []);
        setCategoriesList(categories ?? []);
        setIsFirstExecution(false);
      }
      if (filters.upc.length > 0) {
        articles = articles.filter((f) => filters.upc.includes(f.sku));
      }
      setTableContent(setRows(articles));
    });
    return () => controller.abort("Exit Component");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      providers: selectedProviders,
      category: selectedCategories,
    }));
  }, [selectedProviders, selectedCategories]);

  const filterInputFunct = (params) => {
    const values = params?.UPC?.values ?? [];
    setFilters((prev) => ({ ...prev, upc: values }));
  };

  return (
    <Container>
      <div className="filters">
        <div>
          <FilterInput
            className="filter-input"
            classNameFilterContainer="container-InputSection"
            placeHolderInput="Buscar SKU"
            filterInputFunct={filterInputFunct}
            filtersArray={[{}]}
            filterArray={[{}]}
            hiddenLabelProductsLoaded
            hideButtons
          />
          <CustomSelect
            customSelectId="select-provider"
            selectLabel="Todos los proveedores"
            showSearchBar
            placeHolder={"Buscar proveedor"}
            options={providersList}
            parameterArray={selectedProviders}
            setParameterArray={setSelectedProviders}
          />
          <CustomSelect
            customSelectId="select-categories"
            selectLabel="Todos los Departamentos"
            showSearchBar
            placeHolder={"Buscar departamento"}
            options={categoriesList}
            parameterArray={selectedCategories}
            setParameterArray={setSelectedCategories}
          />
          <CheckBox
            id="checkbox-filter"
            label="Ver solo faltantes de SKU"
            onChange={(e) => {
              const values = e.target.checked ? [undefined] : [];
              setFilters((prev) => ({ ...prev, upc: values }));
            }}
          />
        </div>
        <div className="button" onClick={() => getReport(tableContent)}>
          <Summarize sx={{ fontSize: 20 }} />
        </div>
      </div>
      <div className="table">
        <TableResizable
          headers={tableHeaders}
          tableContent={tableContent}
          minCellWidth={120}
        />
      </div>
    </Container>
  );
}
