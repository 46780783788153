export const userTypes = {
  textSpecialist: {
    role: "textSpecialist",
    name: "Especialista de Textos",
    rejectedStatus: ["RC", "RA"],
  },
  imageSpecialist: {
    role: "imageSpecialist",
    name: "Especialista de Imágenes",
    rejectedStatus: ["RC", "RA"],
  },
  auditor: { role: "auditor", name: "Auditor", rejectedStatus: ["RP", "RCA"] },
};

export const baseColors = [
  "#EC4C4C",
  "#EC9C4C",
  "#E6EC4C",
  "#4CECD6",
  "#4C7CEC",
];
