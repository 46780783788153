import axios from "axios";
import moment from "moment";

export const updateDates = ({ ordersList, orderId, newDeliverDate }) => {
  try {
    const updatedOrdersList = ordersList.map((order) => {
      order.id_order === orderId && (order.deliver_date = newDeliverDate);
      return order;
    });

    const itemFound = ordersList.find((item) => item.id_order === orderId);
    const indexOfItem = ordersList.indexOf(itemFound);

    const data = {
      orderId: ordersList[indexOfItem].id_order,
    };
    const newDate = moment(newDeliverDate).format("YYYY-MM-DD HH:mm:ss");
    data.newDeliverDate = newDate;

    axios.put(
      `${process.env.REACT_APP_ORDER_ENDPOINT}?deliverDateUpdate=true`,
      data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return updatedOrdersList;
  } catch (err) {
    console.log(err, "unable to update deliver date, please try again");
    return ordersList;
  }
};

export const updatePayments = ({ ordersList, orderId, newPaymentStatus }) => {
  try {
    const updatedOrdersList = ordersList.map((order) => {
      order.id_order === orderId && (order.payment = newPaymentStatus);
      return order;
    });

    const data = {
      newPaymentStatus: newPaymentStatus,
      orderId,
    };

    axios.put(
      `${process.env.REACT_APP_ORDER_ENDPOINT}?paymentStatusUpdate=true`,
      data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return updatedOrdersList;
  } catch (err) {
    console.log(err, "unable to update payment status, please try again");
    return ordersList;
  }
};

 export const updateBilling = ({ ordersList, orderId, newBillingStatus }) => {
   try {
     const updatedOrdersList = ordersList.map((order) => {
       order.id_order === orderId && (order.is_billing = newBillingStatus);
       return order;
     });

     const data = {
       newBillingStatus: newBillingStatus,
       orderId,
     };

     axios.put(
       `${process.env.REACT_APP_ORDER_ENDPOINT}?updateBilling=true`,
       data,
       {
         headers: {
           Authorization: sessionStorage.getItem("jwt"),
         },
       }
     );
     return updatedOrdersList;
   } catch (err) {
     console.log(err, "unable to update payment status, please try again");
     return ordersList;
   }
 };
