import {useEffect,useState} from 'react'
import {
    Container,
    ContainerMain,
    Row,
    Title,
    Text18,
    Text14,
    ButtonRecibir,
    Button
} from './styles'
import Leaflet from '../../../components/map/index'
import axios from 'axios';


export default function Entregas(){
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState({});
  const [address, setAddress] =  useState(null);
  const [latlng, setLatlng] = useState({lat: 19.033333, lng: -98.183334});
  const [isLoading, setIsLoading] = useState(true);

  const getAppointments = async() => {
    try{
      setIsLoading(true);
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_APPOINTMENT_ENDPOINT}?isRecepcionist=1`,
        headers: {
          Authorization: sessionStorage.getItem('jwt')
        }
      });
      setIsLoading(false);
      const data = JSON.parse(response.data.body).data
      setAppointmentList(data);
    } catch(err) {
      console.log(err)
    }
  } 

  useEffect(() => {
    getAppointments()
  }, [])

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  return  <ContainerMain>
            <Container className="header-container">
                <Title className="releases">Ordenes de trabajo por recibir</Title>
                <Text18>Lista de citas, eligue el mapa si tienes dudas de la ubicación.</Text18>
            </Container>
            {appointmentList.map((item) => (
                <Row className="row">
                    <Leaflet 
                        className="map-container"
                        draggable={false} 
                        address={address}
                        latlng={latlng}
                        width='200px'
                        height='200px'
                        setLatlng={setLatlng} />
                    <Container className="addres-container">
                        <Text14>
                            ODT: <span>{item.id_cita}</span>
                        </Text14>
                        <Button>{item.type}</Button>
                        <Text14 className="date-container">
                            {new Date(item.date).toLocaleDateString('es-ES', options)}
                        </Text14>
                        <p className="description">
                            - <br/>
                            -
                            {/* {item.desc} <br/>
                            {item.autor} */}
                        </p>
                    </Container>
                    <ButtonRecibir>
                        Recibir
                    </ButtonRecibir>
                </Row>
            ))}
    </ContainerMain>
};