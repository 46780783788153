import { useEffect, useState } from "react";
import { DropDownContainer } from "./styles";
import arrowIcon from "../../../assets/IconComponents/downArrow.svg";
import axios from "axios";

export const Dropdown = (props) => {
  const [option, setOption] = useState("Última versión");
  const [showOptions, setShowOptions] = useState(false);
  const [versions, setVersions] = useState([]);

  const closeDropdown = (e) => {
    if (!e.target.closest("#drop-down-container")) {
      window.removeEventListener("click", closeDropdown, false);
      setShowOptions(false);
    }
  };

  const getVersions = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_VERSIONS_ENDPOINT}?articleId=${props.products[0].article.id_article}&provider=true`,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      const versions = JSON.parse(response.data.body).data;
      setVersions(versions);
      setOption("Versión " + props.products[0].version);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showOptions) {
      window.addEventListener("click", closeDropdown, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  return (
    <DropDownContainer>
      <p className="choose-version">Elige la versión de tus productos</p>
      <div className="dropdown-container">
        <button
          className="option-selected"
          onClick={(e) => {
            e.preventDefault();
            setShowOptions(true);
          }}
        >
          <p>{option}</p>
          <img src={arrowIcon} alt="down arrow" className="arrow-icon" />
        </button>
        {showOptions && (
          <div id="drop-down-container" className="options">
            <ul className="options-list">
              {versions.map(({ version }) => (
                <li
                  className="option-item"
                  onClick={(e) => {
                    setOption(e.target.innerText);
                    setShowOptions(false);

                    let product = props.products;
                    product[0].version =
                      e.target.innerText[e.target.innerText.length - 1];

                    sessionStorage.setItem(
                      "productsToExport",
                      JSON.stringify(product)
                    );

                    window.removeEventListener("click", closeDropdown, false);
                  }}
                >
                  Versión {version}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </DropDownContainer>
  );
};
