import { Container } from "./styles";
import { icons } from "../../utils";
import { SelectV2 } from "contentoh-components-library";
import { useEffect, useState } from "react";
import { ButtonV2 } from "contentoh-components-library";

export const Header = ({
  setUpcList = "",
  retailers = {},
  setFilters,
  billProducts,
  productsToBilling = [],
  handleOnChange,
  setFile,
}) => {
  const [retailerFilter, setRetailerFilter] = useState([]);
  const [text, setText] = useState("");
  const [services, setServices] = useState([]);

  useEffect(() => {
    const tempArray = [];
    const tempObjet = {};
    const retailersArray = Object.values(retailers);
    retailersArray?.forEach((retailer) => {
      if (!tempObjet[retailer.id])
        tempObjet[retailer.id] = { label: retailer.name, value: retailer.id };
    });
    tempArray.push(...Object.values(tempObjet));
    setRetailerFilter(tempArray);
    setServices([
      { label: "Fiche técnica", value: "datasheet" },
      { label: "Descripción", value: "description" },
      { label: "Imágenes", value: "images" },
    ]);
  }, [retailers]);

  return (
    <Container>
      <div className="input-container">
        {icons("search")}
        <input
          type="text"
          placeholder="Ingresa SKUs"
          value={text}
          onInput={(e) => setText(e.value)}
          onPaste={(e) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData("Text");
            const splitText = pastedText.split("");
            let string = "";
            splitText.forEach((e) => {
              string +=
                e.charCodeAt(0) === 13 || e.charCodeAt(0) === 10
                  ? ","
                  : e.trim();
            });
            setText(string);
            setUpcList(string);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setUpcList(e.target.value);
            }
          }}
        />
      </div>
      <SelectV2
        borderType="oval"
        items={retailerFilter}
        triggerType="click"
        selectButton={{
          className: "btnSelectRetailer",
          type: "whiteS3",
          label: "Todas las Cadenas",
          size: 10,
        }}
        multiple
        inputSearch={{
          className: "inputSearchRetailer",
          defaultText: "Buscar Cadena",
        }}
        typeSelectItems={"checkbox"}
        maxHeightDropdown={"50vh"}
        alignmentItemsOverflow={"rows"}
        classNameSelect="selectRetailer"
        classNameDropdown="dropdownSelectRetailer"
        onChange={(selectedItems) => {
          const fltrs = Object.keys(selectedItems || {});
          setFilters((current) => (current = { ...current, retailers: fltrs }));
        }}
      />
      <SelectV2
        borderType="oval"
        items={services}
        triggerType="click"
        selectButton={{
          className: "btnSelectService",
          type: "whiteS3",
          label: "Todos los Servicios",
          size: 10,
        }}
        multiple
        inputSearch={{
          className: "inputSearchService",
          defaultText: "Buscar Servicio",
        }}
        typeSelectItems={"checkbox"}
        maxHeightDropdown={"50vh"}
        alignmentItemsOverflow={"rows"}
        classNameSelect="selectService"
        classNameDropdown="dropdownSelectService"
        onChange={(selectedItems) => {
          const fltrs = Object.keys(selectedItems);
          setFilters((current) => (current = { ...current, services: fltrs }));
        }}
      />
      <ButtonV2
        key={"billingButton"}
        label="Facturar"
        className="billingButton"
        size={12}
        type={"pink"}
        disabled={productsToBilling.length === 0}
        borderType="oval"
        onClick={(event) => {
          billProducts && billProducts(true);
        }}
      />
      <div className="upload-container">
        <p>Facturar desde excel</p>
        <input
          type="file"
          name="uploadBilling"
          id="upload-billing"
          onChange={handleOnChange}
          onClick={(e) => {
            e.target.value = null;
            setFile(null);
          }}
        />
      </div>
    </Container>
  );
};
