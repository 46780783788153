import styled from "styled-components";

export const MainContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #281F3366;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  position: relative;
  padding: 40px 20px;
  box-sizing: border-box;
  width: 600px;
  min-height: ${props => props.height ? '300px' : '190px'};
  background: #281F33;
  border-radius: 39px;
`;

export const TextArea = styled.textarea`
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 479px;
  height: 84px;
  background: #F0EEF2;
  border-radius: 4px;
  color: #817393;
  margin-bottom: 10px;
`

export const Close = styled.figure`
  position: absolute;
  right: 45px;
  > img {
    width: 14px;
    height: 14px;
  }
`;

export const Icon = styled.figure`
  height: ${props => props.sent ? '354px' : '85px'}
  margin-bottom: 10px;
  > img {
    height: ${props => props.sent ? '354px' : '85px'}
    width: ${props => props.sent ? '503px' : '85px'}
  }
`;

export const Image = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Message = styled.p`
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #F7F7F7;
  margin-bottom: 20px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FirstButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 40px;
  border: 1px solid #F0EEF2;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #F0EEF2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
`;

export const Button = styled(FirstButton)`
  border: 0;
  background: #E33AA9;
`;

export const Detail = styled(Message)`
  font-weight: bold;
  font-size: 18px;
`;