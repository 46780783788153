import React, { useState, useEffect } from "react";
import {
  LongItem,
  Row,
  Button,
  ConfirmationRowButton,
  PasswordStrenght,
  ItemPasswordStrength,
} from "./styles";

const ChangePassword = (props) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordToShort, setPasswordToShort] = useState(true);
  const [emptyConfirmationPassword, setEmptyConfirmationPassword] =
    useState(true);
  const [passwordFinal, setPasswordFinal] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  let upperCaseLetters = /[A-Z]/g;
  let numbers = /[0-9]/g;
  let specialChar = /['!','@','#','$','%','^','&','*']/g;

  useEffect(() => {
    passwordFinal === repeatedPassword
      ? setPasswordMatches(true)
      : setPasswordMatches(false);
  }, [passwordFinal]);

  useEffect(() => {
    passwordFinal === repeatedPassword
      ? setPasswordMatches(true)
      : setPasswordMatches(false);
    !repeatedPassword.length > 0
      ? setEmptyConfirmationPassword(true)
      : setEmptyConfirmationPassword(false);
  }, [repeatedPassword]);

  const updateInfo = (e, newValue) => {
    switch (e.target.id) {
      case "newPasswordInput":
        setPasswordStrength(0);
        if (newValue.length >= 8) {
          setPasswordStrength((passwordStrength) => passwordStrength + 1);
        }
        //Verificar si la contraseña contiene mayuscula, numeros y un caracter especial
        newValue.match(upperCaseLetters) &&
          setPasswordStrength((passwordStrength) => passwordStrength + 1);
        newValue.match(numbers) &&
          setPasswordStrength((passwordStrength) => passwordStrength + 1);
        newValue.match(specialChar) &&
          setPasswordStrength((passwordStrength) => passwordStrength + 1);
        //Actualizar value de la contraseña y guardar en sessionStorage
        setPasswordFinal(newValue);
        break;
      case "confirmPasswordInput":
        setRepeatedPassword(newValue);
        break;
    }
  };

  const validateForm = (evt) => {
    evt.preventDefault();
    let password = document.querySelector("#newPasswordInput").value;
    let confirmPassword = document.querySelector("#confirmPasswordInput").value;
    password.length < 8 ? setPasswordToShort(true) : setPasswordToShort(false);
    confirmPassword === ""
      ? setEmptyConfirmationPassword(true)
      : setEmptyConfirmationPassword(false);
    setShowErrors(true);
    let valid = true;
    passwordToShort && (valid = false);
    emptyConfirmationPassword && (valid = false);
    !passwordMatches && (valid = false);
    if (valid) {
      sessionStorage.setItem('newPassword',JSON.stringify(password))
      !sessionStorage.getItem('resetPasswordProcess') ? props.setPaso(9) : props.setPaso(11)
    }
  };

  return (
    <>
      <Row>
        <LongItem>
          <p>Ingrese su nueva contraseña</p>
          <input
            onChange={(e) => updateInfo(e, e.target.value)}
            id="newPasswordInput"
            type="password"
            defaultValue={sessionStorage.getItem('newPassword') ? JSON.parse(sessionStorage.getItem('newPassword')) : ''}
          />
          {passwordToShort && showErrors && (
            <label>La contraseña debe ser minimo de 8 caracteres</label>
          )}
        </LongItem>
      </Row>
      <Row className="passwod-strength">
        <ItemPasswordStrength paso={1}>
          <PasswordStrenght level={1} passwordStrength={passwordStrength} />
          {passwordStrength === 1 && <p>Baja</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={2}>
          <PasswordStrenght level={2} passwordStrength={passwordStrength} />
          {passwordStrength === 2 && <p>Regular</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={3}>
          <PasswordStrenght level={3} passwordStrength={passwordStrength} />
          {passwordStrength === 3 && <p>Segura</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={4}>
          <PasswordStrenght level={4} passwordStrength={passwordStrength} />
          {passwordStrength === 4 && <p>Fuerte</p>}
        </ItemPasswordStrength>
      </Row>
      <Row>
        <LongItem>
          <p>Confirme la nueva contraseña</p>
          <input
            onChange={(e) => updateInfo(e, e.target.value)}
            id="confirmPasswordInput"
            type="password"
            defaultValue={sessionStorage.getItem('newPassword') ? JSON.parse(sessionStorage.getItem('newPassword')) : ''}
          />
          {!passwordMatches && !emptyConfirmationPassword && showErrors && (
            <label>La contraseña no coincide</label>
          )}
          {emptyConfirmationPassword && showErrors && (
            <label>Confirme la contraseña</label>
          )}
        </LongItem>
      </Row>
      <ConfirmationRowButton>
        <Button type="submit" onClick={(e) => validateForm(e)}>
          Envíar
        </Button>
      </ConfirmationRowButton>
    </>
  );
};

export default ChangePassword;
