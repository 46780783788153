import React from "react";
import { Switch, Route } from "react-router-dom";
import { Dashboard } from "contentoh-components-library";
import NotFound from "../pages/NotFound/index";
import CreatorsMetrics from "../pages/CreatorsMetrics";
import OrdersReception from "../pages/Orders";
import Productos from "../pages/Productos_Tareas/index";
import Profile from "../pages/Perfil/index";
import ProductReception from "../pages/Receptionist/index";
import OrderDetails from "../pages/Receptionist/OrderDetails";
import Entregas from "../pages/Proveedores/Entregas/index";
import { RetailerProductEdition } from "contentoh-components-library";
import Quotes from "../pages/Citas/quotes.js";
import { MultipleEdition } from "../pages/Productos_Tareas/multipleEdition";
import TasksView from "../pages/Tasks";
import TutorialsView from "../pages/Tutorials";
import Acquired from "../pages/Acquired";
import VideoDetail from "../pages/VideoDetail";
import { Billing } from "../pages/Proveedores/Billing";
// import Sabana from "../pages/Sabana";
import tutorials from '../pages/Tutorials/utils/tutorials'

export const Routers = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <Switch>
      <Route exact path="/metrics" component={CreatorsMetrics} />
      <Route exact path="/Dashboard">
        <Dashboard
          jwt={sessionStorage.getItem("jwt")}
          user={JSON.parse(sessionStorage.getItem("user"))}
          company={JSON.parse(sessionStorage.getItem("company"))}
        />
      </Route>
      <Route exact path="/DashboardProveedor">
        <Dashboard
          jwt={sessionStorage.getItem("jwt")}
          user={JSON.parse(sessionStorage.getItem("user"))}
          company={JSON.parse(sessionStorage.getItem("company"))}
        />
      </Route>
      <Route exact path="/Orders" component={OrdersReception} />

      {/* Reception routes */}
      <Route exact path="/reception" component={ProductReception} />
      <Route exact path="/reception/:orderId" component={OrderDetails} />

      <Route exact path="/Quotes" component={Quotes} />
      <Route path="/Profile" component={Profile} />
      <Route path="/Entregas" component={Entregas} />
      {user.id_role === 1 && (
        <Route exact path="/Facturacion" component={Billing} />
      )}
      <Route path="/EditProducts">
        <RetailerProductEdition
          productSelected={JSON.parse(
            sessionStorage.getItem("productSelected")
          )}
          user={user}
          token={sessionStorage.getItem("jwt")}
          tabsSections={{
            Descripción: true,
            "Ficha técnica": false,
            Imágenes: false,
          }}
        />
      </Route>
      <Route exact path="/Products">
        <Productos path="Productos" />
      </Route>
      <Route
        exact
        path="/products/multipleEdition"
        component={MultipleEdition}
      ></Route>
      {/* <Route path="/sabana" component={Sabana} /> */}
      <Route exact path="/Tasks">
        <TasksView />
      </Route>
      <Route exact path="/tutorials">
        <TutorialsView tutorials={tutorials} />
      </Route>
      <Route path="/tutorials/:title">
        <VideoDetail tutorials={tutorials} />
      </Route>
      <Route exact path="/Acquired" component={Acquired} />
      <Route path="/tasks/:orderId/:articleId" />
      <Route component={NotFound} />
    </Switch>
  );
};
