import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
//SVG
import LoginImage from "../../assets/IconComponents/LoginImage.svg";
import Elipse from "../../assets/IconComponents/Elipse.svg";
import ElipseB from "../../assets/IconComponents/elipse-white.svg";
import ChangePassword from "./ChangePassword.js";
import contentoh from "../../assets/IconComponents/contentoh.svg";
// Styled-Components
import {
  MainContainer,
  GraphicPanel,
  FormPanel,
  TextUnderImage,
  StepsContainer,
  FormContainer,
} from "./styles";

//Componentes
import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";
import ConfirmAccount from "./ConfirmAccount";
import Loading from "../../components/loading/index";

const Login = () => {
  //Variables para el flujo del componente
  const [paso, setPaso] = useState(7);
  const [image, setImage] = useState(LoginImage);
  const text = "Elige la mejor plataforma que conecta proveedores y retailers";
  const [PasoRegistro, setPasoRegistro] = useState(null);
  const [instruccion, setInstruccion] = useState(
    "Registra tus datos para comenzar"
  );
  const [confirmationError, setConfirmationError] = useState("");
  const [user, setUser] = useState("");

  //ComponentDidMount => Array para guardar la información (usuario y empresa)
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("nuevoRegistro");
      sessionStorage.getItem("confirmationCode") &&
        sessionStorage.removeItem("confirmationCode");
      sessionStorage.getItem("registrationError") &&
        sessionStorage.removeItem("registrationError");
      sessionStorage.getItem("countryCode") &&
        sessionStorage.removeItem("countryCode");
    };
  }, []);

  //on variable paso update
  useEffect(() => {
    pasoGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso]);

  const pasoGraph = () => {
    switch (paso) {
      case 5:
        setImage(LoginImage);
        setInstruccion("Introduzca su código de verificación");
        setPasoRegistro(
          <ConfirmAccount
            confirmationError={confirmationError}
            setConfirmationError={setConfirmationError}
            paso={paso}
            setPaso={setPaso}
          />
        );
        break;
      case 7:
        setInstruccion("Ingresa tus credenciales");
        setPasoRegistro(
          <SignIn setUser={setUser} paso={paso} setPaso={setPaso} />
        );
        break;
      case 8:
        setInstruccion("Cambie su contraseña temporal");
        setPasoRegistro(<ChangePassword paso={paso} setPaso={setPaso} />);
        break;
      case 9:
        confirmPasswordChange();
        break;
      case 10:
        setInstruccion("Ingresa tu correo electrónico:");
        setPasoRegistro(<ResetPassword paso={paso} setPaso={setPaso} />);
        break;
      case 11:
        setInstruccion("Validando código de verificación...");
        forgotPassword();
        break;
      default:
        // setImage(LoginImage);
        break;
    }
  };

  async function forgotPassword() {
    let username = JSON.parse(sessionStorage.getItem("email"));
    let code = JSON.parse(sessionStorage.getItem("confirmationCode"));
    let new_password = JSON.parse(sessionStorage.getItem("newPassword"));
    try {
      setPasoRegistro(<Loading />);
      await Auth.forgotPasswordSubmit(username, code, new_password);
      sessionStorage.removeItem("resetPasswordProcess");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("confirmationCode");
      sessionStorage.removeItem("newPassword");
      sessionStorage.getItem("resetError") &&
        sessionStorage.removeItem("resetError");
      setPaso(7);
    } catch (err) {
      setPaso(5);
      console.log(err);
      switch (err.code) {
        case "CodeMismatchException":
          sessionStorage.setItem(
            "resetError",
            JSON.stringify("Código de confirmación incorrecto")
          );
          setPaso(5);
          break;
        case "LimitExceededException":
          sessionStorage.setItem(
            "resetError",
            JSON.stringify("Has intentado demasiadas veces, intentalo despues")
          );
          break;

        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;
      }
    }
  }

  async function confirmPasswordChange() {
    let newPassword = JSON.parse(sessionStorage.getItem("newPassword"));
    let name = "Colaborador";
    let phone_number = "+521111";
    try {
      setPasoRegistro(<Loading />);
      await Auth.completeNewPassword(user, newPassword, {
        name,
        phone_number,
      });
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("newPassword");
      sessionStorage.getItem("resetPasswordProcess") &&
        sessionStorage.removeItem("resetPasswordProcess");
      setPaso(7);
    } catch (err) {
      switch (err.code) {
        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;

        case "InvalidParameterValue":
          sessionStorage.setItem(
            "changePasswordError",
            JSON.stringify("invalidPassword")
          );
          break;
      }
      setPaso(8);
      console.log(err.message);
    }
  }

  return (
    <MainContainer>
      <GraphicPanel>
        <div className="image-container">
          <figure>
            <img src={image} alt="" />
          </figure>
          <TextUnderImage>
            <p>{text}</p>
          </TextUnderImage>
          <StepsContainer>
            <img src={Elipse} alt="" />
            <img src={ElipseB} alt="" />
            <img src={ElipseB} alt="" />
          </StepsContainer>
        </div>
      </GraphicPanel>
      <FormPanel>
        <div className="login-container">
          <figure>
            <img src={contentoh} alt="" />
          </figure>
          <FormContainer paso={paso}>
            <h3>{instruccion}</h3>
            <form id="formRegister">{PasoRegistro}</form>
          </FormContainer>
        </div>
      </FormPanel>
    </MainContainer>
  );
};
export default Login;
