import styled from "styled-components";

export const RowStatus = styled.div`
  background-color: ${(props) => props.bg};
  font-family: Lato;
  width: ${(props) => (props.width ? props.width : "32px")};
  height: 20px;
  margin: ${(props) => (props.noMargin ? "0" : "0 auto")};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "3px"};
  /* identical to box height, or 138% */
  letter-spacing: -0.015em;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
`;
