import React, { useState, useEffect } from "react"
import axios from 'axios'

import {
  MainContainer,
  Header,
  TitleIcon,
  AboutCompany,
  Textarea,
  BasicData,
  Input,
  Icon, 
  SubmmitButton
} from "./styles";

// SVG assets
import location from '../../assets/IconComponents/locationWhite.svg';
import edit from '../../assets/IconComponents/edit.svg';
import companyIcon from '../../assets/IconComponents/company.svg';
import documentIcon from '../../assets/IconComponents/document.svg';

export default function CompanyData(props){
  const [canEdit, setCanEdit] = useState(false)
  const [company, setCompany] = useState(sessionStorage.getItem("company") 
    ? JSON.parse(sessionStorage.getItem("user")) : {})
  const user = sessionStorage.getItem("user") 
    ? JSON.parse(sessionStorage.getItem("user")) : {}
  const getCompany = async() => {
    const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
      headers: {
        Authorization: sessionStorage.getItem('jwt')
      }
    });
    sessionStorage.setItem('company', JSON.stringify(JSON.parse(response.data.body).data[1]))
    setCompany(JSON.parse(response.data.body).data[1])
  }

  useEffect(() => getCompany(), [])

  const onSubmit = async()  => {
    let data = {
      about_company: document.getElementById("about_company").value,
      adress: document.getElementById("address").value,
      email: document.getElementById("email").value,
      social_link: document.getElementById("social_link").value,
      telephone: document.getElementById("telephone").value,
      web_site: document.getElementById("web_site").value,
      zip_code: document.getElementById("zip_code").value
    }
    try{
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_USER_ENDPOINT}?boolCompany=1`,
        data: data,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setCanEdit(false)
      getCompany()
    }catch(err){
      console.log(err)
    }
  }

  return (
    <MainContainer>
      <Header>
        <div>
          <TitleIcon>
            <img src={companyIcon} alt="Section icon"/>
          </TitleIcon>
          <p>Información de la empresa</p>
        </div>
        <TitleIcon onClick={() => setCanEdit(!canEdit)}>
          <img src={edit} alt="Edit"/>
        </TitleIcon>
      </Header>
      <AboutCompany>
        <p className="title">Acerca de la empresa.</p>
        { canEdit 
        ? <Textarea id="about_company" placeholder="Acerca de la empresa" 
          defaultValue={company.about_company ? company.about_company : ''}/>
        : <p>{company.about_company ? company.about_company : '---'}</p> }
      </AboutCompany>
      <BasicData>
        <div>
          <p className="item">Puesto en la empresa</p>
          <p>{user.position ? `${user.position}` : '---'}</p>
        </div>
        <div>
          <p className="item">Fecha de inicio</p>
          <p>{'---'}</p>
        </div>
      </BasicData>
      <BasicData>
        <div>
          <TitleIcon>
            <img src={location} alt="Location"/>
          </TitleIcon>
          <h4>Ubicación</h4>
        </div>
        <div>
          <p className="item">Dirección</p>
          { canEdit
            ? <Input id="address" placeholder="Dirección" defaultValue={company.adress ? company.adress : ''}/>
            : <p>{company.adress ? company.adress : '---' }</p> }
        </div>
        <div>
          <p className="item">Código Postal</p>
          { canEdit
            ? <Input id="zip_code" placeholder="Código postal" defaultValue={company.zip_code ? company.zip_code : ''}/>
            : <p>{company.zip_code ? company.zip_code : '---' }</p> }
        </div>
        <div>
          <p className="item">Teléfono</p>
          { canEdit
            ? <Input id="telephone" placeholder="Teléfono" defaultValue={company.telephone ? company.telephone : ''}/>
            : <p>{company.telephone ? company.telephone : '---' }</p> }
        </div>
        <div>
          <p className="item">Correo electrónico</p>
          { canEdit
            ? <Input id="email" placeholder="Correo eletrónico" defaultValue={company.email ? company.email : ''}/>
            : <p>{company.email ? company.email : '---' }</p> }
        </div>
      </BasicData>
      <BasicData>
        <div>
          <p className="item">Sitio web</p>
          { canEdit
            ? <Input id="web_site" placeholder="Página web" defaultValue={company.web_site ? company.web_site : ''}/>
            : <p>{company.web_site ? company.web_site : '---' }</p> }
        </div>
      </BasicData>
      <BasicData>
        <div>
          <div className="item">
            <Icon>
              <img src={documentIcon} alt="Location"/>
            </Icon>
            <p>LinkedIn</p>
          </div>
          { canEdit
            ? <Input id="social_link" placeholder="Peril de LinkedIn" defaultValue={company.social_link ? company.social_link : ''}/>
            : <p>{company.social_link ? company.social_link : '---' }</p> }
        </div>
      </BasicData>
      { canEdit && <BasicData>
        <SubmmitButton>
          <p onClick={() => onSubmit() }>Guardar cambios</p>
        </SubmmitButton>
      </BasicData> }
    </MainContainer>
  );
}
