import { useEffect } from 'react'
import { ContainerPrincipal, ContainerText, Img } from './styles'
import notFound from '../../assets/Backgrounds/notFound.svg'
export default function NotFound() {
  return (
    <ContainerPrincipal>
        <Img src={notFound} />
      <ContainerText>Lo sentimos la página que</ContainerText>
      <ContainerText>buscas no existe</ContainerText>
    </ContainerPrincipal>
  )
}
