import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import PopUpRetailers from "../../../components/PopUpRetailers";
import {
  ContainerPrincipal,
  Container,
  TablesHead,
  TablesRowH,
  TablesHeadRow,
  Tables,
  NumberProduct,
  Title,
  TitleFooter,
  TitleComents,
  Subtitle,
  TitleImages,
  TitleVersion,
  TablesRowD,
  TitleContainCat,
  SubtitleContainCat,
  ContainerLeft,
  TitleCoantin,
  TitleCoantinVersion,
  HeaderTitle,
  NewComment,
  RowStatus2,
  ImageTitleContainer,
  Feature,
} from "./styles";
import moment from "moment";
import {
  getStatusColor,
  getShortStatus,
  getLongStatus,
  getImage,
  getProfilePicture,
  getImageType,
  getImagePackingType,
} from "../../../_utils/helper";
import { getComments, postComments, getPercentage } from "../../../_utils/data";
import AddIconEdit from "../../../assets/IconComponents/IconEdit.svg";
import AddIconCancel from "../../../assets/IconComponents/IconCancel.svg";
import redFlag from "../../../assets/IconComponents/flag.svg";
import ReactImageFallback from "react-image-fallback";
import defaultUpdate from "../../../assets/IconComponents/defaultUpdate.png";
import CarrouselImg from "../FinalRevisionComponentes/CarrouselImg.js";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

//Functions
export default function ProductDetail(props) {
  const [inputsDataSheet, setInputsDataSheet] = useState([]);
  const [inputsImages, setInputsImages] = useState([]);
  const [activeImg, setActiveImg] = useState(null);
  const [imagesList, setImagesList] = useState([]);
  const [, setDataImages] = useState([]);
  const [, setGeneralData] = useState([]);
  const [lastComment, setLastComment] = useState(null);
  const [hasOrder, setHasOrder] = useState(null);
  const [comment, setComment] = useState("");
  const [popUpRetailersOpen, setPopUpRetailersOpen] = useState(false);

  const productDetail = JSON.parse(sessionStorage.getItem("productSelected"));
  const [version] = useState(productDetail.version);
  const [percentages, setPercentages] = useState(
    new Array(productDetail.retailers.length).fill({ percentage: 0 })
  );

  const redirectEdit = () => {
    sessionStorage.setItem(
      "productEdit",
      JSON.stringify({
        idCategory: productDetail.article.id_category,
        ArticleId: productDetail.article.id_article,
        product: [productDetail],
      })
    );
    window.location.href = `/EditProducts`;
  };

  useEffect(() => {
    const data = [
      {
        id_article: productDetail.article.id_article,
        id_category: productDetail.article.id_category,
        version: productDetail.version,
        id_retailer_array: productDetail.retailers.map(({ id }) => id),
      },
    ];
    getPercentage({ data }).then((res) => setPercentages(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dataGeneral = {};
    if (productDetail) {
      productDetail.retailers?.forEach((e) => (dataGeneral[e.id] = e));
      // read datasheet inputs
      axios
        .post(
          `${process.env.REACT_APP_ARTICLE_DATA_DATASHEET_ENDPOINT}?idCategory=${productDetail.article.id_category}&articleId=${productDetail.article.id_article}&version=${version}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
        .then((datasheetResponse) => {
          const dataDatasheet = Object.values(
            JSON.parse(datasheetResponse.data.body).data
          );
          const inputsGeneral = dataDatasheet.pop();
          setInputsDataSheet(Object.values(inputsGeneral));
          dataDatasheet.forEach((e) => {
            let itemInputs = [];
            JSON.parse(datasheetResponse.data.body).data[e.retailer.id].data &&
              Object.values(
                JSON.parse(datasheetResponse.data.body).data[e.retailer.id].data
              ).forEach(
                (item) => (itemInputs = itemInputs.concat(item.inputs))
              );
            dataGeneral[e.retailer.id] &&
              (dataGeneral[e.retailer.id].datasheets = itemInputs.map(
                (input) => inputsGeneral[input]
              ));
          });
        });

      // read descriptions inputs
      axios
        .get(
          `${process.env.REACT_APP_ARTICLE_DATA_DESCRIPTION_ENDPOINT}?idCategory=${productDetail.article.id_category}&articleId=${productDetail.article.id_article}&version=${version}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
        .then((descriptionsResponse) => {
          const dataDescriptions = Object.values(
            JSON.parse(descriptionsResponse.data.body).data
          );
          dataDescriptions.forEach(
            (e) =>
              dataGeneral[e.id] && (dataGeneral[e.id].descriptions = e.inputs)
          );
        });
      // read images
      axios
        .post(
          `${process.env.REACT_APP_ARTICLE_DATA_IMAGES_ENDPOINT}?articleId=${productDetail.article.id_article}&version=${version}`,
          {
            category: productDetail.article.id_category,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
        .then((imagesResponse) => {
          const parseData = JSON.parse(imagesResponse.data.body).data;
          const imagesInputs = parseData.inputs;
          setInputsImages(imagesInputs);
          parseData.retailers?.forEach(
            (e) => dataGeneral[e.id] && (dataGeneral[e.id].images = e.inputs)
          );
          const values = parseData?.values;
          const images =
            values?.map((e) => {
              e.name = imagesInputs.find((item) => item.id === e.image_id);
              return getImage(e, 236, 236);
            }) || [];
          images.length > 1 && setActiveImg(images[0]);
          setImagesList(images);
        });
      setGeneralData(dataGeneral);

      // get comments
      updateComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainContainer = useRef();

  useEffect(() => {
    if (mainContainer.current) mainContainer.current.focus();
  }, [props.sidebool]);

  const updateComments = () => {
    getComments({
      articleId: productDetail.article.id_article,
    }).then((commentResponse) => {
      commentResponse.order !== null && setHasOrder(commentResponse.order);
      const commentData = commentResponse.data;
      if (commentData?.length === 1) {
        if (
          JSON.parse(sessionStorage.getItem("user"))?.id_user !==
          commentData[0].user_id
        ) {
          commentData[0].src = getProfilePicture(
            commentData[0].user_id,
            40,
            40
          );
        } else {
          commentData[0].src = JSON.parse(sessionStorage.getItem("user"))?.src;
        }
        setLastComment(commentData[0]);
      }
    });
  };

  const updateRetailersCallback = (articleId, retailers) => {
    const { articleIndex, articlesLists, setArticlesLists } = props;
    if (![null, undefined].includes(articleIndex)) {
      let retailerList;
      if (articlesLists?.productsList?.length > 0) {
        const temp = articlesLists?.productsList.slice();
        const obj = {};
        temp[articleIndex].retailers.forEach((f) => (obj[f.id] = f));
        retailers.forEach((f) => (obj[f.id] = f));
        retailerList = Object.values(obj);
        temp[articleIndex].retailers = retailerList;
        setArticlesLists.setProductsList(temp);
      }
      if (articlesLists?.productsListCopy?.length > 0 && retailerList) {
        const temp = articlesLists?.productsListCopy.slice();
        const index = temp.findIndex((f) => f.article.id_article === articleId);
        if (index >= 0) {
          temp[index].retailers = retailerList;
          setArticlesLists.setProductsListCopy(temp);
        }
      }
      if (articlesLists?.productsListAux?.length > 0 && retailerList) {
        const temp = articlesLists?.productsListAux.slice();
        const index = temp.findIndex((f) => f.article.id_article === articleId);
        if (index >= 0) {
          temp[index].retailers = retailerList;
          setArticlesLists.setProductsListAux(temp);
        }
      }
    }
  };
  const getNewPercentage = (id_article) => {
    const retailers = productDetail?.retailers;
    if (Object.keys(percentages[id_article] ?? {})?.length > 0) {
      retailers?.forEach((retailer, index) => {
        retailer["percentage"] = Number(
          percentages[id_article][retailer.id]?.percentageRequired
        );
      });
    }
  };
  return (
    productDetail &&
    productDetail.article && (
      <ContainerPrincipal
        ref={mainContainer}
        className={props.className}
        id="article-detail"
      >
        <Container width="100%" justifycontent="space-between">
          <HeaderTitle redFlag={redFlag}>
            {productDetail ? productDetail.article.name : ""}
          </HeaderTitle>
          <Container width="90px" justifycontent="space-between">
            <div>
              <img
                onClick={() => {
                  redirectEdit(false);
                }}
                src={AddIconEdit}
                alt="Editar"
              />
            </div>
            <div>
              <img
                id="close-modal-button"
                onClick={() => {
                  setInputsDataSheet([]);
                  setInputsImages([]);

                  setImagesList([]);
                  setDataImages([]);
                }}
                src={AddIconCancel}
                alt="Cerrar"
              />
            </div>
          </Container>
        </Container>
        <Title>{productDetail.article.description}</Title>
        <Title className="date-container">
          {moment(productDetail.article.timestamp).format("DD/MM/YYYY")}
        </Title>
        <Container className="title-container">
          <Subtitle className="upc-container">ODT</Subtitle>
          <Title>{productDetail.orderId}</Title>
        </Container>
        <Container className="title-container">
          <Subtitle className="upc-container">UPC</Subtitle>
          <Title>{productDetail.article.upc}</Title>
        </Container>
        <Container className="progressbar-container">
          <div className="containerEstatus">
            <Subtitle className="title-progress">Estatus</Subtitle>
            <RowStatus2 bg={getStatusColor(productDetail.status)}>
              {productDetail ? getLongStatus(productDetail.status) : "Estatus"}
            </RowStatus2>
          </div>
          <TitleCoantinVersion
            onClick={() => {
              sessionStorage.setItem("versionModal", JSON.parse(true));
              redirectEdit(true);
            }}
          >
            <TitleVersion>Version</TitleVersion>

            <TitleVersion>{productDetail.version}</TitleVersion>
          </TitleCoantinVersion>
        </Container>
        <Container
          className="category-container"
          justifycontent="flex-start"
          width="100%"
        >
          <ContainerLeft className="categiry-container">
            <TitleCoantin>
              <TitleContainCat>Categoria</TitleContainCat>
              <SubtitleContainCat>
                {productDetail.article.category
                  ? productDetail.article.category.replaceAll("|", " / ")
                  : productDetail.article.id_category.replaceAll("|", " / ")}
              </SubtitleContainCat>
            </TitleCoantin>
          </ContainerLeft>
          <TitleCoantin className="container-right">
            {productDetail.retailers ? (
              <>
                <TitleContainCat>Cadenas</TitleContainCat>
                <SubtitleContainCat
                  onClick={() => {
                    const user = JSON.parse(sessionStorage.getItem("user"));
                    [1, 6].includes(user.id_role) &&
                      setPopUpRetailersOpen(!popUpRetailersOpen);
                  }}
                >
                  {productDetail.retailers.map((e, i) =>
                    i < productDetail.retailers.length - 1
                      ? `${e.name}, `
                      : `${e.name}`
                  )}
                </SubtitleContainCat>
                {popUpRetailersOpen && (
                  <PopUpRetailers
                    category={
                      productDetail.article.category ||
                      productDetail.article.id_category
                    }
                    articleId={productDetail.article.id_article}
                    versionId={productDetail.version}
                    orderId={productDetail.orderId}
                    retailersId={productDetail.retailers.map((e) => e.id)}
                    callback={(articleId, retailers) =>
                      updateRetailersCallback(articleId, retailers)
                    }
                  />
                )}
              </>
            ) : (
              <>
                <TitleContainCat>Cadenas</TitleContainCat>
                <SubtitleContainCat>Nombre producto</SubtitleContainCat>
              </>
            )}
          </TitleCoantin>
        </Container>
        <Container className="image-container">
          <Container className="image-container-2" flexdirection="column">
            <ImageTitleContainer
              backgroundColor={getStatusColor(
                productDetail.article.images_status
              )}
            >
              <TitleImages>Fotografía</TitleImages>
              <div className="asig-container">
                <div className="initials">
                  <p>{getShortStatus(productDetail.article.images_status)}</p>
                </div>
                <div className="rounded-images">
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article.id_images_especialist
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article.id_images_facilitator
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                </div>
                <p className="asig-text">Asig.</p>
              </div>
            </ImageTitleContainer>
            <ReactImageFallback
              src={activeImg?.src}
              fallbackImage={defaultUpdate}
              className="containerImg"
            />
            <CarrouselImg
              images={imagesList}
              seleccionado={setActiveImg}
              setDataImages={setDataImages}
              namesList={inputsImages}
              hideTopBar
            />
          </Container>
          <Container
            backgroundColor={getStatusColor(
              productDetail.article.images_status
            )}
            className="product-netwoks"
          >
            <div className="header">
              <ImageTitleContainer
                className="descriptions"
                backgroundColor={getStatusColor(
                  productDetail.article.images_status
                )}
              >
                <TitleImages>Descripciones</TitleImages>
                <div className="asig-container">
                  <div className="initials">
                    <p>{getShortStatus(productDetail.article.images_status)}</p>
                  </div>
                  <div className="rounded-images">
                    <ReactImageFallback
                      src={getProfilePicture(
                        productDetail.article.id_description_especialist
                      )}
                      fallbackImage={defaultProfile}
                      className="containerImg"
                    />
                    <ReactImageFallback
                      src={getProfilePicture(
                        productDetail.article.id_description_facilitator
                      )}
                      fallbackImage={defaultProfile}
                      className="containerImg"
                    />
                  </div>
                  <p className="asig-text">Asig.</p>
                </div>
              </ImageTitleContainer>
              <p className="stat">Stat.</p>
            </div>
            {productDetail.retailers?.map((retailer) => (
              <div className="product-description-container">
                <div className="desc">
                  <p className="name">{retailer.name}</p>
                  <p className="percent">
                    {getNewPercentage(productDetail.article.id_article)}
                    {retailer?.percentage
                      ? retailer.percentage.toFixed(1)
                      : "0"}
                    %
                  </p>
                </div>
                <div className="stat">
                  <p className="initials">
                    {getShortStatus(productDetail.article.images_status)}
                  </p>
                </div>
              </div>
            ))}
          </Container>
        </Container>
        <Container padding="10px 0" justifycontent="space-between">
          <div className="left-table">
            <Feature>
              <p className="bold-text">Toma</p>
              <p className="normal-text">
                {activeImg ? activeImg?.name?.name : "--"}
              </p>
            </Feature>
            <Feature>
              <p className="bold-text">Imagen</p>
              <p className="normal-text">
                {activeImg ? getImageType(activeImg?.image_type) : "--"}
              </p>
            </Feature>
            <Feature>
              <p className="bold-text">Empaque</p>
              <p className="normal-text">
                {activeImg
                  ? getImagePackingType(activeImg?.packing_type)
                  : "--"}
              </p>
            </Feature>
            <ImageTitleContainer className="img-title-container">
              <TitleImages>Editor</TitleImages>
              <div className="asig-container">
                <div className="initials">
                  <p>AA</p>
                </div>
                <div className="rounded-images">
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article?.id_images_especialist
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article?.id_images_facilitator
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                </div>
                <p className="asig-text">Asig.</p>
              </div>
            </ImageTitleContainer>
            <Tables>
              <TablesHead>
                <TablesHeadRow>
                  <TablesRowH align="left" scope="col">
                    Nombre
                  </TablesRowH>
                  <TablesRowH align="right" scope="col">
                    Tamaño
                  </TablesRowH>
                </TablesHeadRow>
              </TablesHead>
              <tbody>
                {imagesList.length > 0 &&
                  imagesList.map(({ name }) => (
                    <TablesHeadRow>
                      <TablesRowD align="left">
                        {name?.name || "Imagen no válida"}
                      </TablesRowD>
                      <TablesRowD align="right">
                        {name?.vertical_px && name?.horizontal_px
                          ? `${name?.vertical_px}x${name?.horizontal_px}`
                          : "- - -"}
                      </TablesRowD>
                    </TablesHeadRow>
                  ))}
              </tbody>
            </Tables>
          </div>
          <div className="right-table">
            <ImageTitleContainer>
              <TitleImages>Ficha técnica</TitleImages>
              <div className="asig-container">
                <div className="initials">
                  <p>AA</p>
                </div>
                <div className="rounded-images">
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article?.id_datasheet_especialist
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                  <ReactImageFallback
                    src={getProfilePicture(
                      productDetail.article?.id_datasheet_facilitator
                    )}
                    fallbackImage={defaultProfile}
                    className="containerImg"
                  />
                </div>
                <p className="asig-text">Asig.</p>
              </div>
            </ImageTitleContainer>
            <Tables className="table-group">
              <TablesHead>
                <TablesHeadRow>
                  <TablesRowH align="left" scope="col">
                    Grupo
                  </TablesRowH>
                  <TablesRowH scope="col"></TablesRowH>
                </TablesHeadRow>
              </TablesHead>
              <tbody>
                {inputsDataSheet.map((input) => (
                  <TablesHeadRow>
                    <TablesRowD align="left">{input?.name}</TablesRowD>
                    <TablesRowD align="right">
                      {input.value ? input.value : "---"}
                    </TablesRowD>
                  </TablesHeadRow>
                ))}
              </tbody>
            </Tables>
          </div>
        </Container>
        {lastComment !== null && (
          <Container className="comments-container">
            <TitleContainCat>Comentarios</TitleContainCat>
            <Container className="comments-avatar">
              <ContainerLeft>
                <ReactImageFallback
                  src={lastComment.src}
                  fallbackImage={defaultProfile}
                  initialImage={spinner}
                  className="avatar-comment"
                />
              </ContainerLeft>

              <TitleComents>{lastComment.message}</TitleComents>
            </Container>
          </Container>
        )}

        <Container className="footer">
          {props.option === "Productos" ? (
            <Container className="title-footer">
              <TitleFooter>Contenido restante</TitleFooter>
              <Title className="footer-title">
                Para este producto requieres completar los siguientes activos,
                puedes agregarlas a tu carrito y nos haremos cargo de entregar
                tus productos de forma correcta
              </Title>
            </Container>
          ) : (
            <Container className="title-footer">
              <TitleFooter>Contenido Total</TitleFooter>
              {/* <NumberProduct>{`$${datasheetPrice + (descriptions*260)}`}</NumberProduct> */}
            </Container>
          )}
          <Container className="summary-container">
            <Container className="resume-product">
              <Container className="total-summary">
                <Title className="title-summary">Productos</Title>
                <NumberProduct>1</NumberProduct>
              </Container>

              <Container className="total-summary">
                <Title className="title-summary">FT</Title>
                {/* <NumberProduct>{FT}</NumberProduct> */}
              </Container>

              <Container className="total-summary">
                <Title className="title-summary">Descripciones</Title>
                {/* <NumberProduct>{descriptions}</NumberProduct> */}
              </Container>
              <Container className="total-summary">
                <Title className="title-summary">Fotografias</Title>
                <NumberProduct>11</NumberProduct>
              </Container>
            </Container>
            {props.option === "Productos" && (
              <Container className="button-container">
                <NumberProduct className="total-price">
                  {/* ${datasheetPrice + (descriptions*260)} */}
                </NumberProduct>
              </Container>
            )}
          </Container>
        </Container>
        <NewComment>
          <form className="text-comment">
            <ContainerLeft>
              <ReactImageFallback
                src={JSON.parse(sessionStorage.getItem("user")).src}
                fallbackImage={defaultProfile}
                initialImage={spinner}
                className="avatar-comment"
              />
            </ContainerLeft>
            <div className="text-button-container">
              <div className="textarea-container">
                <textarea
                  name="comment"
                  id="comment"
                  placeholder="Agregar Comentario"
                  disabled={hasOrder === null}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
              <div className="button-container">
                <button
                  type="button"
                  disabled={hasOrder === null}
                  onClick={() => {
                    postComments({
                      message: comment,
                      articleId: productDetail.id_article,
                      orderId: hasOrder,
                    }).then((res) => {
                      setComment("");
                      updateComments();
                    });
                  }}
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
          <div className="button-validate">
            <button
              type="button"
              onClick={() => {
                redirectEdit();
              }}
            >
              Validar
            </button>
          </div>
        </NewComment>
      </ContainerPrincipal>
    )
  );
}
