import flujoNomal from "../../../assets/imagesTutorials/flujo_normal.png";
import flujoRechazos from "../../../assets/imagesTutorials/flujo_rechazos.png";
import flujoTHD from "../../../assets/imagesTutorials/flujo_thd_golden.png";
import validacionPago from "../../../assets/imagesTutorials/validacion_pago.png";
import plataformas from "../../../assets/imagesTutorials/plataformas.png";
import roles from "../../../assets/imagesTutorials/roles.png";
import tipos from "../../../assets/imagesTutorials/tipos_flujos.png";
import tiposStatus from "../../../assets/imagesTutorials/tipos_status.png";
import agregarColaborador from "../../../assets/imagesTutorials/agregar_colaborador.png";
import agregarServicios from "../../../assets/imagesTutorials/agregar_quitar_servicios.png";
import crearOt from "../../../assets/imagesTutorials/crear_ot.png";
import cuentaFinanciada from "../../../assets/imagesTutorials/cuenta_financiada.png";
import asignacion from "../../../assets/imagesTutorials/asignacion.png";
import calidadInterna from "../../../assets/imagesTutorials/calidad_interna.png";
import capturaDatos from "../../../assets/imagesTutorials/captura_datos.png";
import cargaFotos from "../../../assets/imagesTutorials/carga_fotos.png";
import qa from "../../../assets/imagesTutorials/QA.png";
import recepcion from "../../../assets/imagesTutorials/recepcion.png";
import revisionCadena from "../../../assets/imagesTutorials/revision_cadena.png";
import revisionProveedor from "../../../assets/imagesTutorials/revision_proveedor.png";

const tutorials = [
  {
    image: flujoNomal,
    title: "flujo-plataforma",
    largeTitle: "Flujo normal de plataforma sin rechazos",
    platform: "creators, proveedores",
    description:
      "En este video se mostrará el flujo que sigue la plataforma sin rechazos.",
    shortUrl: "/tutorials/flujo-plataforma",
    pathVideo: "https://www.youtube.com/embed/ix3xNPAL0_s?si=GsLuVJYP20bd5pjo",
  },
  {
    image: flujoRechazos,
    title: "flujo-plataforma-rechazos",
    largeTitle: "Flujo normal de plataforma con rechazos",
    platform: "creators, proveedores",
    description:
      "En este video se mostrará el flujo que sigue la plataforma con rechazos.",
    shortUrl: "/tutorials/flujo-plataforma-rechazos",
    pathVideo: "https://www.youtube.com/embed/IYQDofzzivM?si=4FEz8bXVK4fjcn8-",
  },
  {
    image: flujoTHD,
    title: "flujo-thd-golden",
    largeTitle: "Flujo de The Home Depot Golden",
    platform: "creators, proveedores",
    description:
      "En este video se mostrará cual es el flujo que siguen los produtos de los proveedores financiados.",
    shortUrl: "/tutorials/flujo-thd-golden",
    pathVideo: "https://www.youtube.com/embed/8Xi2Z8ldLl8?si=7us_-RpFtxSsa7Fo",
  },
  {
    image: plataformas,
    title: "plataformas",
    largeTitle: "Plataformas",
    platform: "creators, proveedores",
    description:
      "En Content-oh! contamos con 3 plataformas: proveedores, creators y ticketera.",
    shortUrl: "/tutorials/plataformas",
    pathVideo: "https://www.youtube.com/embed/oLAw9tLU_pE?si=I9rng0s17TYl6D3o",
  },
  {
    image: roles,
    platform: "creators, proveedores",
    title: "roles",
    largeTitle: "Roles",
    description: "En Content-oh! contamos con los siguientes roles.",
    shortUrl: "/tutorials/roles",
    pathVideo: "https://www.youtube.com/embed/ngCMQ5woktw?si=mdFsE7P0EZpxhgkj",
  },
  {
    image: tipos,
    title: "flujos",
    largeTitle: "Tipos de flujos",
    platform: "creators, proveedores",
    description: "En Content-oh! contamos con los siguientes tipos de flujos.",
    shortUrl: "/tutorials/flujos",
    pathVideo: "https://www.youtube.com/embed/9Paf1yXZKi0?si=UiyhlspKcTHKmJKT",
  },
  {
    image: tiposStatus,
    title: "tipos-status",
    largeTitle: "Tipos de status",
    platform: "creators, proveedores",
    description: "En Content-oh! contamos con los siguientes tipos de status.",
    shortUrl: "/tutorials/tipos-status",
    pathVideo: "https://www.youtube.com/embed/sugn6eRkg00?si=6oykkxkKbHGbqasq",
  },
  {
    image: cuentaFinanciada,
    title: "cuentas-financiadas",
    largeTitle: "Cuentas financiadas",
    platform: "creators, proveedores",
    description:
      "En el siguiente video se explicará el funcionamiento de una cuenta financiada.",
    shortUrl: "/tutorials/cuentas-financiadas",
    pathVideo: "https://www.youtube.com/embed/j5Q1z7FUyig?si=ts8mg9ZCWRESpau0",
  },
  {
    image: agregarServicios,
    title: "agregar-quitar-servicios",
    largeTitle: "Agregar o quitar servicios de una OT",
    platform: "creators",
    description:
      "En le siguiente video te mostraremos como agregar o quitar servicios de una OT.",
    shortUrl: "/tutorials/agregar-quitar-servicios",
    pathVideo: "https://www.youtube.com/embed/rhrfsdYK7GA?si=kxxhRghuAo4dQdQD",
  },
  {
    image: agregarColaborador,
    title: "invitar-colaborador",
    largeTitle: "Invitar a un colaborador",
    platform: "creators",
    description:
      "En el siguiente video se explicará como invitar a un colaborador.",
    shortUrl: "/tutorials/invitar-colaborador",
    pathVideo: "https://www.youtube.com/embed/c4xc6CXqLhM?si=PBMWXJJwWk5lciMw",
  },
  {
    image: crearOt,
    title: "crear-ot",
    largeTitle: "Crear una OT",
    platform: "proveedores",
    description:
      "En el siguiente video se mostrará el paso a paso para crear una orden de trabajo.",
    shortUrl: "/tutorials/crear-ot",
    pathVideo: "https://www.youtube.com/embed/NqMTML3CEDg?si=LEq-Nc1OBptZbg0b",
  },
  {
    image: validacionPago,
    title: "validacion-pago-servicios",
    largeTitle: "Validación de Pago de Servicios",
    platform: "proveedores",
    description:
      "En el siguiente video se mostrará como se valida el pago de los servicios.",
    shortUrl: "/tutorials/validacion-pago-servicios",
    pathVideo: "https://www.youtube.com/embed/behUEsU2VJc?si=z5Pwn2s8vWFLr1mC",
  },
  {
    image: recepcion,
    title: "recepcion-productos",
    largeTitle: "Recepción de productos",
    platform: "creators",
    description:
      "En el siguiente video se mostrará como se realiza la recepción de productos.",
    shortUrl: "/tutorials/recepcion-productos",
    pathVideo: "https://www.youtube.com/embed/0qMU_pOh6Ks?si=Lo9qGo2as-O0vK6l",
  },
  {
    image: asignacion,
    title: "asignacion-actividades",
    largeTitle: "Asignación de actividades",
    platform: "creators",
    description:
      "En el siguiente video se mostrará como se realiza la asignación de actividades.",
    shortUrl: "/tutorials/asignacion-actividades",
    pathVideo: "https://www.youtube.com/embed/X3PwMa6--T0?si=2hRrfWw3msT5iooo",
  },
  {
    image: capturaDatos,
    title: "carga-datos-descripciones",
    largeTitle: "Carga de datos y captura de descripciones",
    platform: "creators",
    description:
      "En el siguiente video se mostrará como se realiza la carga de datos y captura de descripciones por los especialistas de textos.",
    shortUrl: "/tutorials/carga-datos-descripciones",
    pathVideo: "https://www.youtube.com/embed/_yYu9ql3iJA?si=6LFpSSsnvjg_lDQe",
  },
  {
    image: cargaFotos,
    title: "carga-fotografias",
    largeTitle: "Carga de fotografías",
    platform: "creators",
    description:
      "En el siguiente video se mostrará como se realiza la carga de fotografías por los especialistas de edición.",
    shortUrl: "/tutorials/carga-fotografias",
    pathVideo: "https://www.youtube.com/embed/nDMDKdIC1Aw?si=YqZcVqt8aZT39LKD",
  },
  {
    image: qa,
    title: "revision-informacion-qa",
    largeTitle: "Revisión de información de QA",
    platform: "creators",
    description:
      "En el siguiente video se mostrará como se realiza la revisión de información por el Auditor.",
    shortUrl: "/tutorials/revision-informacion-qa",
    pathVideo: "https://www.youtube.com/embed/Ncu7LPw43pg?si=a8gr_eNVcwhl4A8c",
  },
  {
    image: revisionProveedor,
    title: "revision-informacion-proveedor",
    largeTitle: "Revisión de información por proveedor",
    platform: "proveedores",
    description: "En el siguiente video se mostrará la manera en que se realiza la revisión de información por proveedor.",
    shortUrl: "/tutorials/revision-informacion-proveedor",
    pathVideo: "https://www.youtube.com/embed/XyVXzyMXqPU?si=nVLbbB-Itn18zlUf",
  },
];

export default tutorials;
