import styled from "styled-components";

export const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #817393;
    min-height: 18px;
  }

  .title-container {
    h3 {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #503d66;
      min-height: 21px;
    }
  }

  .side-container {
    display: flex;
    align-items: center;

    p {
      & + * {
        margin-left: 27px;
      }
    }

    .buttons-container {
      display: flex;
      button {
        background-color: transparent;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;

        &.save-icon {
          width: 30px;
          height: 30px;
          border: 1px solid lightgray;
          border-radius: 50%;

          img {
            width: 17px;
            margin: auto;
          }
        }

        & + * {
          margin-left: 10px;
        }

        &.send-to-evaluation {
          border-radius: 15px;
          width: fit-content;
          font-size: 15px;
          background-color: #71de56;
          color: #fff;
          padding: 0 15px;
        }

        &.unable-button {
          border-radius: 15px;
          width: fit-content;
          font-size: 15px;
          background-color: #817393;
          color: #fff;
          padding: 0 15px;
        }
      }
    }
  }

  & + * {
    margin-top: 15px;
  }
`;
