import styled from "styled-components";
import editIcon from "../../../assets/IconComponents/EditIcon.svg";
import unchecked from "../../../assets/IconComponents/retailerUnchecked.svg";
import checked from "../../../assets/IconComponents/retailerChecked.svg";

const PopUpContainer = styled.div`
  background-color: #f0eef2;
  border-radius: 7px;
  position: absolute;
  top: calc(100% + 10px);
  padding: 10px 14px;
  z-index: 10;

  .categories-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #817393;

      & + button {
        margin-left: 15px;
      }
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &.category-container {
    width: 426px;
    left: 0;
  }

  &.chk-inputs {
    width: 534px;
    max-height: 200px;
    overflow: auto;
    right: 0;
    padding: 13px 20px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #817393;
      border-radius: 100px;
    }

    .retailers-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .retailer-container {
        flex: 1 0 25%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        input {
          display: none;

          &:checked {
            & + label {
              &:before {
                background-image: url(${checked});
              }
            }
          }
        }

        label {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: Avenir Next;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 21px;
          color: #817393;
          position: relative;
          padding-left: 25px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(${unchecked});
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        & + * {
          margin-top: 5px;
        }

        &:nth-child(-n + 4) {
          margin-top: 0;
        }
      }
    }
  }
`;

const PopUpEdit = (props) => {
  const defaultCheckedInput = (retailerName) => {
    return (
      props.retailersAvailable.filter(
        (retailer) => retailer.name === retailerName
      ).length > 0
    );
  };

  return (
    <PopUpContainer id="popup-component" className={props.className}>
      <div className="elements-container">
        {props.categories && (
          <div className="categories-container">
            <p>{props.categories}</p>
            <button>
              <img src={editIcon} alt="Edit icon" />
            </button>
          </div>
        )}
        {props.retailers && (
          <div className="retailers-container">
            {props.retailers.map((retailer, index) => (
              <div key={index} className="retailer-container">
                <input
                  type="checkbox"
                  id={"chk" + index}
                  name={"chk" + index}
                  defaultChecked={retailer.value}
                  onChange={() => props.handleUpdateCadena(index)}
                />
                <label htmlFor={"chk" + index}>
                  {retailer.label || retailer.name}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </PopUpContainer>
  );
};

export default PopUpEdit;
