import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// Styled components
import {
  MainContainer,
  Title,
  SubTitle,
  Container,
  Icon,
  Table,
  TableHeader,
  TableCol,
  TableRow,
  TableContainer,
  ProductCount,
} from "./styles.js";

//FUNCTIONS
import {
  getShortStatus,
  getStatusColor,
  getRegionFlag,
  getPrio,
} from "../../_utils/helper";

// SVG
import searchIcon from "../../assets/IconComponents/search.svg";
import optionIcon from "../../assets/IconComponents/option-circle.svg";

// Components
import InputSearch from "../../components/customInputs/InputSearch";
import ExportAlert from "../../components/alerts/exportAlert";
import Loading from "../../components/loading/index";
import StatusOrders from "../../components/statusTable/StatusOrders.js";
import profilePic4 from "../../assets/IconComponents/profile_picture_4.svg";

const ProductReception = () => {
  const headers = [
    "Reg.",
    "Orden",
    "Productos",
    "Proveedor",
    "Estatus",
    "Prio",
    "Fecha",
    "Entrega",
    "Asignado",
  ];

  const [modalView, setModalView] = useState(null);

  const [ordersList, setordersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemsListReceptionist, setItemsListReceptionist] = useState([]);
  const [auxListReceptionist, setAuxListReceptionist] = useState([]);

  const getAsignations = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isAsignRoles=1`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const asignations = JSON.parse(response.data.body).data[4];
    let arr1 = [];
    for (let i = 0; i < asignations[4].length; i++) {
      if (asignations[4][i] != null) {
        arr1.push({
          image: profilePic4,
          text: `${asignations[4][i].name} ${asignations[4][i].last_name}`,
          userId: asignations[4][i].id_user,
        });
      }
    }
    setItemsListReceptionist(arr1);
    setAuxListReceptionist(arr1);
  };

  useEffect(() => {
    getOrders();
    getAsignations();
  }, []);

  const getOrders = async () => {
    setLoading(true);
    const ordersResponse = await axios.get(
      `${process.env.REACT_APP_ORDER_ENDPOINT}?reception=true`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const orders = JSON.parse(ordersResponse.data.body).data;
    for (let i = 0; i < orders.length; i++) {
      orders[i].modal = false;
    }
    setordersList(Object.values(orders));
    setLoading(false);
  };

  const orderDetail = (order) => {
    window.location.href = `reception/${order.id_order}`;
  };

  return (
    <MainContainer>
      <Container className="main-container">
        <Container className="title-container">
          <Title>Lista de Órdenes</Title>
          <SubTitle>
            En esta lista encontrarás todas las ordenes solicitadas a contentoh.
          </SubTitle>
        </Container>
        <Container className="cards-container">
          <ProductCount color="#E67432">
            <p className="title">Por recibir</p>
            <p className="amount">13</p>
          </ProductCount>

          <ProductCount color="#ED9A4D">
            <p className="title">Recibidos</p>
            <p className="amount">4</p>
          </ProductCount>

          <ProductCount color="#F0EEF2" bg="#F0EEF2">
            <p className="title">En proceso</p>
            <p className="amount">34</p>
          </ProductCount>
        </Container>
      </Container>
      <Container
        id="button-container"
        padding=" 20px 0px"
        justifycontent="flex-end"
      >
        <InputSearch id="search" icon={searchIcon} alt="Buscar" />
        <Icon>
          <img src={optionIcon} alt="Opción" />
        </Icon>
        <div className="export-button-container">
          <ExportAlert modalView={modalView} setModalView={setModalView} />
        </div>
      </Container>
      <TableContainer>
        {!loading ? (
          <Table>
            {/*TABLE HEADERS*/}
            <TableHeader>
              <input type="checkbox" disabled />
            </TableHeader>
            {headers.map((header) => (
              <TableHeader>{header}</TableHeader>
            ))}

            {ordersList.length > 0 &&
              ordersList.map((item, i) => (
                <TableRow>
                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    <input type="checkbox" className="chk-orders" />{" "}
                    {/* CHECKS DE LAS ORDENES */}
                  </TableCol>

                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    <figure>
                      <img
                        className="image"
                        alt=""
                        src={getRegionFlag(item.order.country)}
                      />
                    </figure>
                  </TableCol>

                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    {item.order.id_order}
                  </TableCol>

                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    {item.order.qtyProducts}
                  </TableCol>

                  <TableCol
                    className="image"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    {item.order.company_name}
                  </TableCol>

                  <TableCol
                    className="status"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    <StatusOrders
                      width={"70%"}
                      status={getShortStatus(item.order.status)}
                      color={getStatusColor(item.order.status)}
                    />
                  </TableCol>

                  <TableCol
                    className="image"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    <figure>
                      <img src={getPrio(item.order.prio)} alt="" />
                    </figure>
                  </TableCol>

                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    {moment(item.order.deliver_date).format("DD/MM/YYYY")}
                  </TableCol>

                  <TableCol
                    onClick={(evt) => {
                      evt.stopPropagation();
                      orderDetail(item.order);
                    }}
                  >
                    {item.order.type}
                  </TableCol>

                  <TableCol width="23%" />
                </TableRow>
              ))}
          </Table>
        ) : (
          <Loading />
        )}
      </TableContainer>
    </MainContainer>
  );
};

export default ProductReception;
