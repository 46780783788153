import styled from "styled-components";

export const MainContainer = styled.div`
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
`;

export const Section = styled.div`
  width: 95%; 
  border-bottom: 1px solid #E2E2E2;
  padding: 14px 0px;
  p{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  } 
`;

export const Header = styled(Section)`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  div{
    display: flex; 
    flex-direction: row;
    align-items: center;
  }
  p{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

export const AboutCompany = styled(Section)`
  .title{
    font-weight: bold;
  }
`;

export const BasicData = styled(Section)`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  box-sizing: border-box;
  div{
    display: flex; 
    flex-direction: column;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #969696;
    input{
      width: 244px;
      height: 30px;
      padding: 6px;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 7px;
      margin-right: 50px;
      ::placeholder{
        color: #CDCDCD;
      }

      & + * {
        margin-top: 5px;
      }
    }
    p:first-child{
      font-size: 12px;
      line-height: 16px;
      color: #707070;
      font-weight: 400;

      & + * {
        margin-top: 5px;
      }
    }
    a{
      text-decoration: none;
      color: #D43594;
      :hover{
        text-decoration: underline;
      }
    }
    button{
      font-size: 18px;
      line-height: 25px;
      color: #FFFFFF;
      width: 200px;
      height: 40px;
      margin: 15px 0;
      background: #D43594;
      border-radius: 30px;
      border-width: 0;
      :hover {
        cursor:pointer;
      }
    }
    label{
      font-size:10px;
      color:red;
    }
  }
  .align_right{
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;