import styled from "styled-components";

export const VideoDetailContainer = styled.div`


    .title-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .description-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:20px;
        iframe{
            border-radius:0.5rem;
        }
    }

    .title-video{
        text-transform: capitalize;
    }

    .details-video{
        font-size: 12px;
        font-weight: 400;
        text-align: left;    
        color:#707070;
        text-transform: capitalize;
    }

    .description-video{
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        text-align: left;
        color:#707070;
    }

    .title-division{
        display:flex;
        border-bottom:1px solid #F0F0F0;
        padding-bottom:20px;
        margin-right:30px;
        justify-content: space-between;
    }

    .container-button{
        display: flex;
        align-items: center;
    }

    .button-return{
        min-height: 28px;
        padding: 5px 10px;
        border-radius: 20px;
        background:#F7F7FC;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        text-align: right;
        color:#8A6CAA;
    }

    .arrow-icon{
        margin-right:5px;
    }

`;
