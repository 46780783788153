import { MultipleEditionContainer } from "./styles";

import { NavigationBar } from "./navigationBar/index";
import { TablesSection } from "./tablesSection";
import { useEffect, useState } from "react";
import { ExitModal } from "./uploadImageModal/exitModal";
import Loading from "../../../components/loading";

import axios from "axios";

export const MultipleEdition = () => {
  const [navSelected, setNavSelected] = useState("datasheet");
  const [dataTable, setDataTable] = useState([]);
  const [informationHeader, setInformationHeader] = useState([]);
  const [productsList, setProductsList] = useState(
    JSON.parse(sessionStorage.getItem("multipleEditionList"))
  );
  const [loading, setLoading] = useState(true);
  const [dataSheetsHeader, setDataSheetsHeader] = useState([]);
  const [dataSheets, setDataSheets] = useState([]);
  const [imagesHeader, setImagesHeader] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesKeys, setImageKeys] = useState([]);
  const [descriptionsHeader, setDescriptionsHeader] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [keys, setKeys] = useState([]);
  const [exitModal, setExitModal] = useState(false);
  const [updatedDatasheets, setUpdatedDatasheets] = useState([]);
  const [updatedDescriptions, setUpdatedDescriptions] = useState([]);
  const [nullRequired, setNullRequired] = useState({
    datasheet: {},
    description: {},
    images: {},
  });

  const getArticlesAndVersion = () => {
    let articles = "";
    let versions = "";
    productsList.forEach((product) => {
      articles += product.article.id_article + ",";
      versions += product.version + ",";
    });
    return articles.slice(0, -1) + "|" + versions.slice(0, -1);
  };

  const getDataSheets = async (value) => {
    const array = value?.split("|");
    const articles = array[0];
    const versions = array[1];
    const dataSheets = axios.get(
      `${
        process.env.REACT_APP_MULTIPLE_DATASHEETS
      }?articles=${encodeURIComponent(articles)}&versions=${encodeURIComponent(
        versions
      )}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const descriptions = axios.get(
      `${
        process.env.REACT_APP_MULTIPLE_DESCRIPTIONS
      }?articles=${encodeURIComponent(articles)}&versions=${encodeURIComponent(
        versions
      )}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const images = axios.get(
      `${process.env.REACT_APP_MULTIPLE_IMAGES}?articles=${encodeURIComponent(
        articles
      )}&versions=${encodeURIComponent(versions)}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const arrayResopnse = await Promise.all([dataSheets, images, descriptions]);

    let tempArray = [];
    let keysArray = [];
    let imagesKeys = [];
    let imagesHeader = [];
    let descriptionsHeader = {};

    const response = JSON.parse(arrayResopnse[0].data.body).attributes;
    const imagesResponse = JSON.parse(arrayResopnse[1].data.body);
    const infoResponse = JSON.parse(arrayResopnse[0].data.body).data;

    Object.values(response).forEach((element) => tempArray.push(element.name));
    Object.keys(response).forEach((key) => keysArray.push(key));
    Object.values(imagesResponse.typesShot).forEach((element) =>
      imagesHeader.push(element.name)
    );
    Object.keys(imagesResponse.typesShot).forEach((key) =>
      imagesKeys.push(key)
    );
    sessionStorage.setItem(
      "imageType",
      JSON.stringify(imagesResponse.imageType)
    );
    sessionStorage.setItem(
      "packagingType",
      JSON.stringify(imagesResponse.packagingType)
    );

    JSON.parse(arrayResopnse[2].data.body).attributes &&
      Object.values(JSON.parse(arrayResopnse[2].data.body).attributes).forEach(
        (attribute) => {
          descriptionsHeader[attribute.name] = [];
          Object.values(attribute).forEach(
            ({ name }) => name && descriptionsHeader[attribute.name]?.push(name)
          );
        }
      );

    let requiredDatasheets = {};
    dataSheets &&
      productsList?.forEach(({ article, statusByRetailer }) => {
        infoResponse[article.id_article]?.retailer?.forEach((retailer) => {
          retailer?.attributes?.forEach((attr) => {
            if (!requiredDatasheets[article.id_article])
              requiredDatasheets[article?.id_article] = 0;
            statusByRetailer[retailer.id]?.datasheet !== "NS" &&
              infoResponse[article.id_article]?.attributes[attr]?.required &&
              infoResponse[article.id_article]?.attributes[attr]?.value
                ?.length === 0 &&
              requiredDatasheets[article.id_article]++;
          });
        });
      });
    const descriptionsArray = JSON.parse(arrayResopnse[2].data.body).data;

    let requiredDescriptions = {};
    descriptionsArray &&
      productsList.forEach(({ article, statusByRetailer }) => {
        if (!requiredDescriptions[article.id_article])
          requiredDescriptions[article.id_article] = 0;
        const articleId = article.id_article;
        descriptionsArray[articleId]?.retailer?.forEach((ret) => {
          statusByRetailer[ret.id] &&
            ret?.descriptions?.forEach((description) => {
              const value = descriptionsArray[articleId]?.descriptions[
                description
              ]?.value?.replace(/<.*?>/gm, "");

              const isAttributeRequired =
                descriptionsArray[articleId]?.descriptions[description]
                  .required;
              (!value || value === "") &&
                isAttributeRequired &&
                requiredDescriptions[article.id_article]++;
            });
        });
      });
    let requiredImages = {};

    imagesResponse &&
      productsList.forEach(({ article, statusByRetailer }) => {
        imagesResponse.data[article.id_article]?.retailer?.forEach(
          (retailer) => {
            if (!requiredImages[article.id_article])
              requiredImages[article.id_article] = 0;
            statusByRetailer[retailer.id] &&
              retailer.images.forEach((img) => {
                imagesResponse?.data[article.id_article]?.images[img]
                  ?.mandatory &&
                  !imagesResponse?.data[article.id_article].images[img].value &&
                  requiredImages[article.id_article]++;
              });
          }
        );
      });

    setNullRequired({
      datasheet: requiredDatasheets,
      description: requiredDescriptions,
      images: requiredImages,
    });

    setInformationHeader(tempArray);
    setDataTable(infoResponse);

    setDataSheetsHeader(tempArray);
    setDataSheets(infoResponse);

    setImagesHeader(imagesHeader);
    setImages(imagesResponse.data);
    setImageKeys(imagesKeys);

    setDescriptions(JSON.parse(arrayResopnse[2].data.body));
    setDescriptionsHeader(descriptionsHeader);

    setKeys(keysArray);
    setLoading(false);
  };

  useEffect(() => {
    getDataSheets(getArticlesAndVersion());
    return () => {
      sessionStorage.getItem("imageType") &&
        sessionStorage.removeItem("imageType");
      sessionStorage.getItem("packagingType") &&
        sessionStorage.removeItem("packagingType");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (navSelected) {
      case "datasheet":
        setInformationHeader(dataSheetsHeader);
        break;

      case "images":
        setInformationHeader(imagesHeader);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navSelected]);

  const saveAll = async () => {
    setLoading(true);
    let dataToSave = [];
    let promisesArray = [];
    switch (navSelected) {
      case "datasheet":
        dataToSave = updatedDatasheets;
        setUpdatedDatasheets([]);
        break;
      case "description":
        dataToSave = updatedDescriptions;
        setUpdatedDescriptions([]);
        break;

      default:
        dataToSave = [];
        break;
    }

    dataToSave.forEach((data) => {
      let version = data.version;
      delete data.version;
      promisesArray.push(
        axios.put(
          `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?${navSelected}=true&version=${version}`,
          data,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
      );
    });

    await Promise.all(promisesArray);
    await getDataSheets(getArticlesAndVersion());
  };

  return (
    <MultipleEditionContainer>
      <NavigationBar
        setNavSelected={setNavSelected}
        setExitModal={setExitModal}
      />
      {loading ? (
        <Loading />
      ) : (
        <TablesSection
          dataTable={dataTable}
          dataSheets={dataSheets}
          descriptions={descriptions}
          nullRequired={nullRequired}
          images={images}
          imagesKeys={imagesKeys}
          informationHeader={informationHeader}
          descriptionsHeader={descriptionsHeader}
          navSelected={navSelected}
          productsList={productsList}
          setProductsList={setProductsList}
          keys={keys}
          saveAll={saveAll}
          updatedDatasheets={updatedDatasheets}
          setUpdatedDatasheets={setUpdatedDatasheets}
          updatedDescriptions={updatedDescriptions}
          setUpdatedDescriptions={setUpdatedDescriptions}
          setLoading={setLoading}
        />
      )}
      {exitModal && <ExitModal setExitModal={setExitModal} />}
    </MultipleEditionContainer>
  );
};
