import styled from "styled-components";

export const FilterInput = styled.div`
  background-color: #fafafa;
  border: 1px solid #e33aa9;
  box-sizing: border-box;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;

  .real-input {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #e33aa9;

    img {
      & + * {
        margin-left: 9px;
      }
    }

    input {
      border: none;
      background-color: transparent;
      width: 100%;
    }
  }

  .filters-container {
    display: flex;

    .filters {
      flex: 0%;
      display: flex;
      /* max-width: 642px; */
      flex-wrap: wrap;

      & > div {
        margin: 0 3px;
        margin-top: 10px;
      }
    }

    .button-container {
      width: 123px;
      display: flex;
      align-items: flex-end;

      button {
        width: 100%;
        background-color: #e33aa9;
        border-radius: 40px;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        border: none;
        color: #fff;
        cursor: pointer;
        padding: 2.5px;
      }
    }
  }

  * {
    box-sizing: border-box;
  }
`;
