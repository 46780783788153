import styled from "styled-components";

export const Filter = styled.div`
  position: relative;

  .option-selected {
    padding: 4px 5px 4px 14px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #d4d1d7;
      white-space: nowrap;

      & + * {
        margin-left: 5px;
      }
    }

    .remove-filter {
      background-color: transparent;
      border: none;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
    }
  }

  .filters-selection {
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 3px;
    padding-left: 30px;
    padding-right: 6px;
    cursor: pointer;

    label {
      cursor: pointer;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #e33aa9;
      position: relative;

      :before {
        right: calc(100% + 3px);
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
`;

export const FilterPopUp = styled.div`
  position: absolute;
  background: #f0eef2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
  padding-left: ${(props) => (props.display === "flex" ? "15px" : "30px")};
  z-index: 5;
  left: 0;
  top: 100%;
  min-width: 100%;

  .flex-container {
    display: ${({ display }) => display ?? "block"};
    width: ${({ display }) => (display === "flex" ? "590px" : "auto")};
    height: ${({ display }) => (display === "flex" ? "171px" : "auto")};
    overflow-y: ${({ display }) => (display === "flex" ? "auto" : "visible")};
    flex-wrap: wrap;

    & > div {
      flex: ${(props) => (props.display === "flex" ? "1 0 45%" : "1 0 25%")};
      min-width: 250px;
      margin-left: ${(props) => (props.display === "flex" ? "30px" : "auto")};

      label {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 21px;
        color: #817393;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 21px;
      color: #817393;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .search-container {
    margin-bottom: 10px;
    width: 100%;
    input {
      width: 100%;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #817393;
      border: 1px solid #f0eef2;
      border-radius: 10px;
      padding: 7px 10px;
    }
  }
`;
