import styled from "styled-components"

export const MainContainer = styled.div`
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  margin-bottom: 10px;
`;

export const Section = styled.div`
  width: 95%; 
  border-bottom: 1px solid #E2E2E2;
  padding: 14px 0px;
  p{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  } 
`; 

export const Header = styled(Section)`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  div{
    display: flex; 
    flex-direction: row;
    align-items: center;
  }
  p{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503D66;
  }
`; 

export const AboutCompany = styled(Section)`
  padding: 13px 8px;
  box-sizing: border-box;
  .title{
    font-weight: bold;
    color: #503D66;
  }
`; 

export const BasicData = styled(Section)`
  div{
    height: 28px;
    margin-bottom: 4px;
    display: flex; 
    align-items: center;
    .title,
    .item{
      width: 35%; 
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color:  #503D66;

      & + p {
        color: #817393;
      }
    };
    

    h4{
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503D66;
    }

    figure {
      margin: 0;
      & + *{
        margin-left: 13px;
      }
    }
  }
`; 

export const Icon = styled.figure`
  margin: 0 10px;
  align-items: center;
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 28px;
  padding: 0 7px;
  display: felx;
  align-items: center;
  border: 1px solid #D4D1D7;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #D4D1D7;
  }
`;

export const Input = styled.input`
  width: 190px;
  height: 28px;
  margin-bottom: 4;
  display: flex;
  align-items: center;
  border: 1px solid #D4D1D7;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #D4D1D7;
  }
`;

export const SubmmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  >p{
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 34px;
    background: #D43594;
    border-radius: 30px;
    color: #FFF;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
  }
`;