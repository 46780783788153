import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Row, LongItem, Item, RowButton, Button } from "./styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/loading/index";
import { getProfilePicture } from "../../_utils/helper";

export default function SignIn(props) {
  const history = useHistory();
  const [emptyEmail, setEmptyEmail] = useState(true);
  const [emptyPassword, setEmptyPassword] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [signInError, setSignInError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setSignInError("");
    setShowErrors(true);
    let valid = true;
    let email = document.querySelector("#usernameInput").value;
    let password = document.querySelector("#passwordInput").value;
    if (email === "") {
      valid = false;
      setEmptyEmail(true);
    } else {
      setEmptyEmail(false);
    }
    if (password === "") {
      valid = false;
      setEmptyPassword(true);
    } else {
      setEmptyPassword(false);
    }
    if (valid) {
      try {
        setLoading(true);
        const session = await Auth.signIn(email, password);
        if (session.challengeName === "NEW_PASSWORD_REQUIRED") {
          props.setUser(session);
          props.setPaso(8);
        } else {
          const userGroup =
            session.signInUserSession.accessToken.payload["cognito:groups"];
          const response = await axios.get(
            process.env.REACT_APP_USER_ENDPOINT,
            {
              headers: {
                Authorization: session.signInUserSession.idToken.jwtToken,
              },
            }
          );

          const userGroupValue =
            typeof userGroup === "string" ? userGroup : userGroup[0];
          if (userGroupValue === "colaboradores_contentoh") {
            sessionStorage.setItem("auth", true);
            sessionStorage.setItem(
              "jwt",
              session.signInUserSession.idToken.jwtToken
            );
            const user = JSON.parse(response.data.body).data[0];
            const company = JSON.parse(response.data.body).data[1];
            // const picture = getProfilePicture(user.id_user, 83, 83);
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            user.src = `https://${
              process.env.REACT_APP_IMAGES_PROFILE_BUCKET
            }.s3.amazonaws.com/id-${user.id_user}/${
              user.id_user
            }.png?${new Date().getTime()}`;
            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("company", JSON.stringify(company));
            history.push({ pathname: "/dashboard" });
          } else {
            setSignInError("NotAuthorizedException");
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error.code === "NotAuthorizedException") {
          setSignInError("NotAuthorizedException");
        } else if (error.code === "UserNotConfirmedException") {
          sessionStorage.setItem(
            "email",
            JSON.stringify(document.querySelector("#usernameInput").value)
          );
          props.setPaso(5);
        } else {
          setSignInError("Error");
        }
      }
    }
  };

  useEffect(() => {
    sessionStorage.getItem("resetPasswordProcess") &&
      sessionStorage.getItem("resetPasswordProcess");
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <LongItem>
          <p>Nombre de usuario</p>
          <input
            required
            id="usernameInput"
            placeholder="username@contentoh.com"
            type="text"
          />
          {showErrors && emptyEmail && (
            <label>Ingrese su correo electrónico</label>
          )}
        </LongItem>
      </Row>
      <Row>
        <LongItem>
          <p>Contraseña</p>
          <input
            required
            id="passwordInput"
            placeholder="Escribe tu contraseña"
            type="password"
          />
          {showErrors && emptyPassword && <label>Ingrese su contraseña</label>}
        </LongItem>
      </Row>
      <Row>
        <Item className="row">
          <input type="checkbox" id="checkbox" className="checkbox" />
          <label for="checkbox" className="checkboxLabel">
            Mantener sesión activada
          </label>
        </Item>
        <Item className="row">
          <label onClick={() => props.setPaso(10)} className="checkboxLabel">
            Olvidé mi contraseña
          </label>
        </Item>
      </Row>
      <LongItem>
        {showErrors && signInError === "NotAuthorizedException" && (
          <label>Correo o contraseña incorrectos</label>
        )}
        {showErrors && signInError === "Error" && (
          <label>Ha habido un problema al iniciar sesión</label>
        )}
      </LongItem>
      <RowButton>
        <Button onClick={(e) => onSubmit(e)} type="submit">
          Iniciar Sesión
        </Button>
      </RowButton>
    </>
  );
}
