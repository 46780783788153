import { useState } from "react";

import { TableTitle } from "../tableTitle";
import {
  SideTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  FixedHeader,
  FixedCell,
  EstatusCell,
} from "./styles";
import { DinamicCell } from "./dinamicCell";
import { CheckboxCustom } from "../../../../components/customInputs/checkboxCustom";
import { RetailersCell } from "./retailersCell";
import { ImageCell } from "./imageCell";

// import { UploadImagemodal } from "../uploadImageModal/index";
import {
  getStatusColor,
  getShortStatus,
  isUserAssigned,
  isStatusCorrect,
} from "../../../../_utils/helper";
import { useEffect } from "react";

export const DinamicTable = (props) => {
  const tableHeader = props.tableHeader;
  const productsList = props.productsList;
  const tableTitle = props.tableTitle;
  const tableInfo = props.tableInfo;
  const subTitle = props.subTitle;
  // const [modalImage, setModalImage] = useState(false);
  const [rowsSelected, setRowsSelected] = useState(0);
  const dataSheets = props.dataSheets;
  const images = props.images;
  const [productsForEvaluation, setProductsForEvaluation] = useState([]);
  const [canEvaluate, setCanEvaluate] = useState(false);
  const handleSelected = (index, checked, item) => {
    if (checked) {
      setProductsForEvaluation((prev) => [
        ...prev,
        props.productsOriginalFormat[index],
      ]);
    } else {
      let listCopy = productsForEvaluation.slice();
      const indexItem = listCopy.indexOf(
        listCopy.find(
          (prod) =>
            prod.article.name === item[1][0] && prod.version === item[6][0]
        )
      );
      listCopy.splice(indexItem, 1);
      setProductsForEvaluation(listCopy);
    }
  };

  useEffect(() => {
    //if user is assigned to all selected producst and status is correct enable evaluation buttons
    let valid = true;
    productsForEvaluation.forEach((product) => {
      const serviceIsRequest = product[`${props.concept}_status`] !== "NS";
      if (!valid || !serviceIsRequest) return;
      const isAssigned = isUserAssigned(product.article, props.concept);
      const isValidStatus = isStatusCorrect(product, props.concept);
      valid = isAssigned && isValidStatus;
    });
    setCanEvaluate(valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsForEvaluation, props.concept, productsList]);

  const list = JSON.parse(sessionStorage.getItem("multipleEditionList"));
  const { setArticlesSelected, articlesSelected } = props;

  const renderHeader = (headerType, index) => {
    switch (headerType) {
      case "check":
        return (
          <TableHeader key={"header" + index}>
            <CheckboxCustom id="chk-global" htmlFor="chk-global" />
          </TableHeader>
        );
      case "Cadenas":
        return (
          <TableHeader key={"header" + index}>
            <p>{headerType}</p>
          </TableHeader>
        );

      default:
        return (
          <TableHeader key={"header" + index}>
            <p>{headerType}</p>
          </TableHeader>
        );
    }
  };

  const checkAll = (e) => {
    const checkboxs = document.querySelectorAll(".chk-element");
    checkboxs.forEach((checkbox) => (checkbox.checked = e.target.checked));
    setArticlesSelected(e.target.checked ? list : []);
    setProductsForEvaluation(e.target.checked ? list : []);
    setRowsSelected(e.target.checked ? list.length : 0);
  };

  const renderFixedHeader = (headerType, index) => {
    switch (headerType) {
      case "check":
        return (
          <FixedCell key={"header" + index}>
            <CheckboxCustom
              id="chk-global-fixed"
              htmlFor="chk-global-fixed"
              onChange={(e) => checkAll(e)}
            />
          </FixedCell>
        );
      case "Cadenas":
        return (
          <FixedCell key={"header" + index}>
            <p>{headerType}</p>
          </FixedCell>
        );

      default:
        return (
          <FixedCell key={"header" + index}>
            <p>{headerType}</p>
          </FixedCell>
        );
    }
  };

  const renderCells = (value, rIndex, index) => {
    if (productsList) {
      switch (index) {
        case 4:
          return (
            <RetailersCell
              index={`${rIndex}-${index}`}
              value={value[0]}
              retailers={value[1]}
              key={`${rIndex}-${index}`}
            />
          );
        case 5:
          // If is task sent from contentoh return article status
          const estatus =
            props.productsOriginalFormat[rIndex][`${props.concept}_status`] ||
            "-";
          return (
            <TableCell key={`${rIndex}-${index}`}>
              <EstatusCell color={getStatusColor(estatus)}>
                {getShortStatus(estatus)}
              </EstatusCell>
            </TableCell>
          );

        // return (
        //   <TableCell key={"cell" + index} textAlign="center">
        //     <div className="image-container">
        //       <ReactImageFallback
        //         src={getProfilePicture(value, 40, 40)}
        //         fallbackImage={defaultProfile}
        //         alt="User"
        //       />
        //     </div>
        //   </TableCell>
        // );
        case 6:
          return (
            <TableCell
              key={"cell" + index}
              className="version"
              textAlign="center"
            >
              <p>{value}</p>
            </TableCell>
          );
        default:
          return (
            <TableCell key={"cell" + index}>
              <p>{value}</p>
            </TableCell>
          );
      }
    } else if (props.navSelected === "images") {
      return Date.parse(value) ? (
        <DinamicCell value={value} index={index} key={`${rIndex}-${index}`} />
      ) : (
        <ImageCell
          value={value}
          rIndex={rIndex}
          index={index}
          required={value?.mandatory}
          articlesSelected={articlesSelected}
          imageId={value?.id_image}
          images={props.images}
          key={`${rIndex}-${index}`}
          // setModalImage={setModalImage}
        />
      );
    } else {
      return (
        <DinamicCell
          value={value}
          index={index}
          articlesSelected={articlesSelected}
          navSelected={props.navSelected}
          rIndex={rIndex}
          dataSheets={dataSheets}
          descriptions={props.descriptions}
          updatedDatasheets={props.updatedDatasheets}
          setUpdatedDatasheets={props.setUpdatedDatasheets}
          updatedDescriptions={props.updatedDescriptions}
          setUpdatedDescriptions={props.setUpdatedDescriptions}
          key={`${rIndex}-${index}`}
        />
      );
    }
  };

  return (
    <SideTable className={props.className}>
      <TableTitle
        title={tableTitle}
        tableInfo={tableInfo}
        subTitle={subTitle}
        rowsSelected={rowsSelected}
        nullRequired={props.nullRequired}
        canEvaluate={canEvaluate}
        productsForEvaluation={productsForEvaluation}
        concept={props.concept}
        setProductsList={props.setProductsList}
        productsOriginalFormat={props.productsOriginalFormat}
        saveAll={props.saveAll}
        setLoading={props.setLoading}
      />
      <div className="scrollable-container">
        <FixedHeader>
          {tableHeader?.length > 0 &&
            tableHeader?.map((header, index) =>
              renderFixedHeader(header, index)
            )}
        </FixedHeader>
        <div
          className={"table-container " + props.scrollClass}
          onScroll={props.onScroll}
          id={props.id}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader?.length > 0 &&
                  tableHeader?.map((header, index) =>
                    renderHeader(header, index)
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList?.map((listItem, rIndex) => (
                <TableRow key={"row" + rIndex}>
                  {props.showCheckbox && (
                    <TableCell className="chk-container">
                      {
                        <CheckboxCustom
                          id={"chk-edition-" + rIndex}
                          htmlFor={"chk-edition-" + rIndex}
                          className={"chk-element"}
                          onChange={(e) => {
                            let count = rowsSelected;
                            if (e.target.checked) {
                              count++;
                            } else {
                              count--;
                            }
                            if (props.tasks) {
                              handleSelected(
                                rIndex,
                                e.target.checked,
                                listItem
                              );
                              const temp = articlesSelected.slice();
                              temp.push(list[rIndex]);
                              setArticlesSelected(temp);
                            } else {
                              const temp = articlesSelected.filter(
                                (f) => f.id_article !== list[rIndex].id_article
                              );
                              setArticlesSelected(temp);
                            }
                            document.getElementById(
                              "chk-global-fixed"
                            ).checked = count === productsList.length;
                            setRowsSelected(count);
                          }}
                        />
                      }
                    </TableCell>
                  )}
                  {Object.values(listItem)?.map((value, index) =>
                    renderCells(value, rIndex, index)
                  )}
                </TableRow>
              ))}
              {props.navSelected === "datasheet" &&
                Object.values(dataSheets)?.map((element, rIndex) => (
                  <TableRow key={"row" + rIndex}>
                    {props.keys?.map((key, index) =>
                      renderCells(
                        element?.attributes[key]
                          ? element?.attributes[key]
                          : undefined,
                        rIndex,
                        index
                      )
                    )}
                  </TableRow>
                ))}
              {props.navSelected === "images" &&
                Object.values(images)?.map((element, rIndex) => (
                  <TableRow key={"row" + rIndex}>
                    {props.imagesKeys?.map(
                      (key, index) =>
                        element?.images &&
                        renderCells(element?.images[key] || {}, rIndex, index)
                    )}
                  </TableRow>
                ))}
              {props.navSelected === "description" &&
                props.descriptionsArray?.map((description, rIndex) => (
                  <TableRow key={"row" + rIndex}>
                    {props.tableKeys.map((key, index) =>
                      renderCells(
                        description[key] ? description[key] : undefined,
                        rIndex,
                        index
                      )
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {/* {modalImage && <UploadImagemodal setModalImage={setModalImage} />} */}
    </SideTable>
  );
};
