import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { isElementClicked } from "../../_utils/helper";

// import SVG
import arrowDown from "../../assets/IconComponents/arrow-down.svg";

const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 120px;
  height: 30px;
  border: 1px solid #e33aa9;
  border-radius: 7px;
  box-sizing: border-box;
  background: #fff;
`;

const Input = styled.input`
  box-sizing: border-box;
  border-width: 0;
  width: calc(100% - 11px);
  font-size: 12px;
  /* color: #D4D1D7; */
  padding-left: 5px;
`;

const Icon = styled.figure`
  display: flex;
  align-items: center;
  width: 13px;
  height: 100%;
  cursor: pointer;
  border-left: 1px solid #e33aa9;

  > img {
    width: 6px;
    margin: auto;
  }
`;

const ListItems = styled.div`
  position: absolute;
  top: 100%;
  z-index: 5;
  width: max-content;
  min-width: 100%;
  max-height: 100px;
  background-color: #fff;
  border: 1px solid #e33aa9;
  border-radius: 7px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-top: 5px;
  ${({ right, left }) =>
    right &&
    !left &&
    `
    right: 0;
  `};
  ${({ right, left }) =>
    left &&
    !right &&
    `
    left: 0;
    margin-left: 0 !important;
  `};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;

const ItemIcon = styled.figure`
  display: flex;
  align-items: center;
  width: 15px;
  height: 100%;
  margin-right: 5px;
  > img {
    width: 100%;
  }
`;

export default function Select /* Props is a parameter that is passed to the function. */(
  props
) {
  const [isOpen, setIsOpen] = useState(false);
  const [valueSelected, setValueSelected] = useState(
    props.defaultValue
      ? props.defaultValue
      : props.list
      ? props.list[0]?.name
      : ""
  );
  const originalList = props.list;
  const [list, setList] = useState(props.list);

  const changeValue = (value) => {
    setValueSelected(value);
    //setIsOpen(!isOpen);
  };

  const filter = (value) => {
    if (value) {
      setList(
        originalList.filter(
          (e) => `${e.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      );
    } else {
      setList(originalList);
    }
  };

  const clickOutsideToClose = (evt) => {
    if (!evt.target.closest(`#customSelect-${props.id}`) && isOpen) {
      document.removeEventListener("click", clickOutsideToClose);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", clickOutsideToClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <MainContainer id={`customSelect-${props.id}`}>
      <Input
        id={props.id}
        type="text"
        defaultValue={valueSelected}
        onChange={(event) => {
          filter(event.target.value);
          setValueSelected(event.target.value);
        }}
        onFocus={() => setIsOpen(true)}
        autoComplete="off"
        value={valueSelected}
      />
      <Icon
        onClick={() => {
          setIsOpen(!isOpen);
          setList(originalList);
        }}
      >
        <img src={arrowDown} alt={isOpen ? "Cerrar" : "Abrir"} />
      </Icon>
      {/* Solo se debe mandar una propiedad ya sea right o left, esto debido a que se utlizará 
    para decir desde donde se quiere anclar el menú por si hay un item que sobrepase el 
    tamaño del contenedor padre (MainContainer) */}
      {isOpen && (
        <ListItems left={props.left} right={props.right}>
          {list?.length > 0 ? (
            list.map((e, i) => (
              <Item
                key={i}
                isSelected={valueSelected === e.name}
                onClick={() => {
                  changeValue(e.name);
                  props.filter && props.setFilter(!props.show);
                  props.onChange && props.onChange(e);
                  props.setValueSelected && props.setValueSelected([e.value]);
                  props.setFirstFilter && props.setFirstFilter(e.value);
                }}
              >
                {e.icon && (
                  <ItemIcon>
                    <img src={e.icon} alt={e.name} />
                  </ItemIcon>
                )}
                <p>{e.name}</p>
              </Item>
            ))
          ) : (
            <Item>
              <p>No existen elementos</p>
            </Item>
          )}
        </ListItems>
      )}
    </MainContainer>
  );
}
