import { useState } from "react";
import { FilterPopUp } from "./styles";
import { CheckboxCustom } from "../../customInputs/checkboxCustom";

export const FiltersPopUp = (props) => {
  const sortByName = (items) => {
    const ordered = items.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    if (props.filterName === "Orden") return ordered.reverse();
    return ordered;
  };
  const [list, setList] = useState(sortByName(props.filters));

  const returnFilter = (key) => {
    switch (key) {
      case "Región":
        return "country";
      case "Compania":
        return "company_name";
      case "Orden":
        return "orderId";
      case "Cadena":
        return "retailers";
      case "Estatus":
        return "status";
      case "Prioridad":
        return "prio";
      case "Marca":
        return "brand";
      default:
        return key;
    }
  };

  const isInArray = (array, value) => array.indexOf(value) !== -1;

  function copy(obj) {
    let result;
    if (obj instanceof Array) {
      result = [...obj];
    } else if (typeof obj === "object") {
      result = { ...obj };
    } else {
      return obj;
    }
    for (let prop of Reflect.ownKeys(result)) {
      result[prop] = copy(result[prop]);
    }
    return result;
  }

  const search = (value) => {
    if (value.length === 0) {
      setList(sortByName(props.filters));
      return;
    }
    const newValues = [];
    props.filters.forEach((item) => {
      if (`${item.name}`.toLowerCase().includes(`${value}`.toLowerCase()))
        newValues.push(item);
    });
    setList(sortByName(newValues));
  };

  return (
    <FilterPopUp display={list.length > 20 ? "flex" : "block"}>
      {props.filters?.length > 15 && (
        <div className="search-container ">
          <input
            type="text"
            placeholder="Buscar..."
            onChange={(e) => search(e.target.value)}
          />
        </div>
      )}
      <div className="flex-container">
        {list?.length ? (
          list
          ?.filter((f) => f.name !== '')
          ?.map((filter, index) => (
            <CheckboxCustom
              key={"chk-option-" + index}
              labelText={filter.name}
              id={"chk-filter" + index}
              name={"chk-filter" + index}
              htmlFor={"chk-filter" + index}
              isChecked={
                props.globalFilters[props.filterName] &&
                isInArray(
                  props.globalFilters[props.filterName]?.values,
                  filter.value
                )
              }
              onChange={(e) => {
                let filtersObject = copy(props.globalFilters);
                if (e.target.checked) {
                  if (filtersObject[props.filterName]) {
                    let inArray = isInArray(
                      filtersObject[props.filterName].values,
                      filter.value
                    );
                    if (!inArray) {
                      filtersObject[props.filterName].values.push(filter.value);
                    }
                  } else {
                    filtersObject[props.filterName] = {
                      filter: returnFilter(props.filterName),
                      values: [filter.value],
                    };
                  }
                } else {
                  let index = filtersObject[props.filterName].values.indexOf(
                    filter.value
                  );
                  filtersObject[props.filterName].values.splice(index, 1);
                  if (filtersObject[props.filterName].values.length === 0)
                    delete filtersObject[props.filterName];
                }
                props.setGlobalFilters(filtersObject);
              }}
            />
          ))
        ) : (
          <p>No se encontraron resultados...</p>
        )}
      </div>
    </FilterPopUp>
  );
};
