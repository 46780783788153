import styled from "styled-components";

const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();
  // Prevent the page/container scrolling
  e.stopPropagation();
  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => e.target.focus(), 0);
};

export const Container = styled.div`
  display: grid;
  grid-auto-rows: 75px 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr;
  gap: 10px 0px;
  grid-template-areas:
    "filters"
    "table";

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 50px;
    & > div {
      display: flex;
      align-items: center;
      div {
        margin-right: 5px;
      }
      div:first-child {
        margin-bottom: 0;
        width: 200px;
        .real-input {
          width: 100%;
        }
        .option-selected {
          visibility: hidden;
        }
      }
    }
    & > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 25%;
      border: 1px solid #ddd;
      cursor: pointer;
    }
  }
  .pointer {
    cursor: pointer;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  .img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

export const Input = styled.input.attrs({
  type: "number",
  onWheel: (evt) => numberInputOnWheelPreventChange(evt),
})`
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 4px 0 4px 8px;
  width: 90%;
`;
