import React, { useState, useEffect } from "react";
import { getTasks, fetchUsers } from "../../_utils/data";
import { Redirect } from "react-router-dom";
import { runAssignations } from "../../_utils/products-tasks/userAssignationsFunctions";
import Loading from "../../components/loading/index";
import ProductDetail from "../../pages/Productos_Tareas/productDetail/index";
import axios from "axios";
import { enabledFilters, filterBySections } from "./utils/filtersOptions";
import { FilterInput } from "../../components/filterInput/index";
import {
  statusFilterOptions,
  priorityFilterOptions,
} from "../../_utils/helper";
import {
  Container,
  Title2,
  SubTitle,
} from "../../pages/Productos_Tareas/styles";
import { VirtualizedContainer } from "./styles";
import TableTasks from "./Table";

const TasksView = () => {
  const [userTasks, setUserTasks] = useState([]);
  const [initialUserTasks, setInitialUserTasks] = useState([]);
  const [tasksLoaded, setTasksLoaded] = useState(false);
  const [usersByRole, setUsersByRole] = useState([]);
  const [activeClass, setActiveClass] = useState("");

  const [ordersOptions, setOrdersOptions] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [rowClicked, setRowClicked] = useState();
  const [initialSettingsLoad, setInitialSettingsLoad] = useState(true);
  const [redirectMultipleEdition, setRedirectMultipleEdition] = useState(false);

  useEffect(() => {
    const setInitialTasks = async () => {
      try {
        const tasksRetrieved = await getTasks();
        setUsersByRole(await fetchUsers());
        setUserTasks(tasksRetrieved);
        setInitialUserTasks(tasksRetrieved);
        let uniqueOrders = [
          ...new Set(tasksRetrieved.map((item) => item.orderId)),
        ];
        uniqueOrders = uniqueOrders.map((order) => ({
          name: order,
          value: order,
        }));

        let uniqueCompanies = [
          ...new Set(tasksRetrieved.map((item) => item.article?.company_name)),
        ];
        uniqueCompanies = uniqueCompanies.map((company) => ({
          name: company,
          value: company,
        }));

        let uniqueRegions = [
          ...new Set(tasksRetrieved.map((item) => item.article?.country)),
        ];
        uniqueRegions = uniqueRegions.map((order) => ({
          name: order,
          value: order,
        }));

        let uniqueBrand = [
          ...new Set(tasksRetrieved.map((item) => item.brand)),
        ];
        uniqueBrand = uniqueBrand.filter((brand) => !!brand);
        uniqueBrand = uniqueBrand.map((brand) => ({
          name: brand,
          value: brand,
        }));

        setCompaniesOptions(uniqueCompanies);
        setOrdersOptions(uniqueOrders);
        setRegionOptions(uniqueRegions);
        setBrandOptions(uniqueBrand);
        setTasksLoaded(true);
      } catch (err) {
        console.log(
          err,
          "Unable to mount initial user tasks and get tasks filters, please report this to IT"
        );
      }
    };
    setInitialTasks();
  }, []);

  useEffect(() => {
    if (userTasks.length > 0 && initialSettingsLoad) {
      setInitialSettingsLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTasks]);

  useEffect(() => {
    if (tasksLoaded) {
      const allProductsAreSelected =
        productsSelected.length === userTasks.length;
      document.getElementById("globalHeaderCheckbox").checked =
        allProductsAreSelected;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsSelected, userTasks]);

  const checkboxClicked = (checked, product) => {
    try {
      if (checked) {
        setUserTasks((prev) =>
          prev.map((item) => {
            item.orderId === product.orderId &&
              item.article?.id_article === product.article?.id_article &&
              (item.checked = true);
            return item;
          })
        );
        setProductsSelected((prev) => [...prev, product]);
      } else {
        const updatedArticles = productsSelected.slice();
        let productIndex = updatedArticles.indexOf(product);
        updatedArticles.splice(productIndex, 1);
        setUserTasks((prev) =>
          prev.map((item) => {
            item.orderId === product.orderId &&
              item.article?.id_article === product.article?.id_article &&
              (item.checked = false);
            return item;
          })
        );
        setProductsSelected(updatedArticles);
      }
    } catch (err) {
      console.log(
        err,
        `Unable to update selected products with: ${product?.orderId}/${product?.article?.id_article}`
      );
    }
  };

  const checkAllItems = (checked) => {
    if (checked) {
      setUserTasks((prev) =>
        prev.map((item) => {
          item.checked = true;
          return item;
        })
      );
      setProductsSelected(userTasks);
    } else {
      setUserTasks((prev) =>
        prev.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setProductsSelected([]);
    }
  };

  const assignUser = (
    productData,
    assigneeID,
    assignationTarget,
    concept,
    target
  ) => {
    try {
      const assignationForAuditor = target === "auditor";
      const serviceStatus = `${concept}_status`;
      const productsArray = [];
      const data = {
        concept,
        userId: assigneeID,
      };

      let newList = userTasks.slice();
      let newAuxList = initialUserTasks.slice();
      let selectedItems = productsSelected.slice();

      const isArticleDifferentFromSelected =
        selectedItems.find(
          (item) =>
            item.orderId === productData.orderId &&
            item.article.id_article === productData.article.id_article
        ) === undefined;
      //ARTICLE CLICKED IS NOT PART OF SELECTED PRODUCTS, PUSH TO PRODUCT ARRAY AND UPDATE ASSIGNEE
      if (isArticleDifferentFromSelected) {
        const differentArticleModified = runAssignations(
          productData,
          newList,
          serviceStatus,
          assignationTarget,
          assigneeID,
          assignationForAuditor
        );
        newList = differentArticleModified.updatedList;

        productsArray.push({
          orderId: productData.orderId,
          articleId: productData.article.id_article,
        });
      }

      if (selectedItems.length > 0) {
        selectedItems.forEach((item, i) => {
          productsArray.push({
            orderId: item.orderId,
            articleId: item.article.id_article,
          });
          const updatedList = runAssignations(
            item,
            newList,
            serviceStatus,
            assignationTarget,
            assigneeID,
            assignationForAuditor
          );

          newList = updatedList.updatedList;

          const updatedAuxList = runAssignations(
            item,
            newAuxList,
            serviceStatus,
            assignationTarget,
            assigneeID,
            assignationForAuditor
          );
          newAuxList = updatedAuxList.updatedList;
        });
      }

      data.articleList = productsArray;

      //Update assignations in database
      axios.post(process.env.REACT_APP_ASSIGNATIONS_ENDPOINT, data, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      // Finally update productslist for re-render
      setUserTasks(newList);
      setInitialUserTasks(newAuxList);
    } catch (err) {
      console.log(err, "Unable to execute assignations, please try again");
    }
  };

  const handleRowClicked = (clickedId, item) => {
    const assignComponentWasNotClicked = !clickedId.startsWith("assign");
    if (assignComponentWasNotClicked) {
      sessionStorage.setItem("productSelected", JSON.stringify(item));
      sessionStorage.setItem("version", JSON.stringify(item.version));
      setRowClicked(item);
      setShowProductDetail(true);
    }
  };

  const openModal = (e) => {
    if (
      (!e.target.closest("#article-detail") && showProductDetail) ||
      e.target.id === "close-modal-button"
    ) {
      document.removeEventListener("click", openModal, false);
      setActiveClass("");
      setTimeout(() => setShowProductDetail(false), 500);
    }
  };

  const multipleEditionRedirect = () => {
    if (productsSelected.length > 0) {
      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(productsSelected)
      );
      setRedirectMultipleEdition(true);
    }
  };

  useEffect(() => {
    if (showProductDetail) {
      setActiveClass("active-modal");

      setTimeout(() => {
        document.addEventListener("click", openModal, false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProductDetail]);

  const filterInputFunct = async (filtersObject) => {
    try {
      const userCheckedProducts = productsSelected.length > 0;
      if (tasksLoaded) {
        const filters = Object.values(filtersObject);
        if (filters.length > 0) {
          const userRole = JSON.parse(sessionStorage.getItem("user")).id_role;
          const userIsFacilitator = [4, 5].includes(userRole);

          let listToFilter = userCheckedProducts
            ? initialUserTasks.slice().map((item) => {
                item.checked = false;
                return item;
              })
            : initialUserTasks.slice();

          filters?.forEach((filterParams) => {
            if (userIsFacilitator && filterParams.filter === "status")
              listToFilter = filterBySections(
                listToFilter,
                userRole,
                filterParams.values
              );
            else
              listToFilter = listToFilter.filter((product) =>
                filterParams.values.includes(
                  product[filterParams.filter] ||
                    product.article[filterParams.filter]
                )
              );
          });
          setUserTasks(listToFilter);
        } else {
          setUserTasks(
            initialUserTasks.map((item) => {
              item.checked = false;
              return item;
            })
          );
        }
        setProductsSelected([]);
      }
    } catch (err) {
      console.log(err, "Unable to filter data");
      setUserTasks(initialUserTasks);
    }
  };

  return (
    <>
      <Container className="main-container">
        <Container className="title-container">
          <Title2>Productos por realizar</Title2>
          <SubTitle>
            Una vez asignada la tarea, aquí te mostraremos el estatus de esta,
            estaras compartiendo en tiempo real tus estatus.
          </SubTitle>
        </Container>
      </Container>
      <FilterInput
        filterInputFunct={filterInputFunct}
        charged={userTasks.length}
        total={initialUserTasks.length}
        statusOption={statusFilterOptions}
        priorityOption={priorityFilterOptions}
        brandOption={brandOptions}
        ordersOption={ordersOptions}
        companiesOption={companiesOptions}
        countryOption={regionOptions}
        enabledFilters={enabledFilters}
        editProducts={multipleEditionRedirect}
        taskFilter
      />
      {tasksLoaded ? (
        <VirtualizedContainer>
          <TableTasks
            articlesList={userTasks}
            usersByRole={usersByRole}
            assignUser={assignUser}
            checkboxClicked={checkboxClicked}
            checkAllItems={checkAllItems}
            rowClicked={handleRowClicked}
          />
        </VirtualizedContainer>
      ) : (
        <Loading />
      )}
      {showProductDetail && (
        <ProductDetail
          productDetail={rowClicked}
          sidebool={showProductDetail}
          className={activeClass}
        />
      )}
      {redirectMultipleEdition && (
        <Redirect
          to={{
            pathname: `/products/multipleEdition`,
          }}
        />
      )}
    </>
  );
};

export default TasksView;
