import React, { useState, useEffect } from "react";
import { Chart } from "chart.js";
import styled from "styled-components";
import ChartDataLabels from "chartjs-plugin-datalabels";

const MainContainer = styled.div`
  width: ${(props) => (props.width ? props.width + "%" : "100%")};
  height: 380px;
  border: 1px solid #d4d1d7;
  border-radius: 7px;
  padding: 20px 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  > canvas {
    border: 1px solid #d4d1d7;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 20px;
    height: 90% !important;
  }
`;

const HeaderChart = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: space-around;
    .worked::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #4cecd6;
      margin: 0 5px;
    }

    .rejected::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #ec4c4c;
      margin: 0 5px;
    }
  }
`;

const label = (args, labels) => {
  const { dataIndex, raw } = args;
  return ` ${labels[dataIndex]}: ${raw}`;
};

const labelColor = (args) => ({
  backgroundColor: args.dataset.backgroundColor[0],
  borderWidth: 0,
  borderRadius: 4,
  borderColor: "rgba(0, 0, 0, .1)",
});

const title = (args, names) => {
  const [{ dataIndex }] = args;
  return `${names[dataIndex]}`;
};

const footer = (args, values = {}) => {
  const [{ dataIndex }] = args;
  if (!values[dataIndex]) return "";
  return Object.entries(values[dataIndex])
    .map(([key, value]) => `${key}: ${value}`)
    .join("\n");
};

const GeneralChart = (props) => {
  const [chart, setChart] = useState();

  useEffect(() => {
    if (chart) chart.destroy();
    const img = new Image(25, 25);
    img.src = "https://i.stack.imgur.com/2RAv2.png";
    const img2 = new Image(25, 25);
    img2.src = "https://i.stack.imgur.com/9EMtU.png";
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      borderRadius: 25,
      plugins: {
        datalabels: {
          color: "#262626",
          align: "end",
        },
        interaction: {
          mode: "index",
        },
        tooltip: {
          callbacks: {
            title: (args) => title(args, props.longNames),
            label: (args) => label(args, props.labels),
            footer: (args) => footer(args, props.footerValues),
            labelColor: (args) => labelColor(args),
          },
          backgroundColor: "#E9E9E9",
          titleColor: "#262626",
          bodyColor: "#262626",
          footerColor: "#262626",
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            textAlign: "left",
            boxWidth: 8,
          },
          display: false,
        },
        counter: {
          fontFamily: "sans-serif",
          fontSize: 25,
          fontColor: "#707070",
          textAlign: "center",
          value: props.total || 0,
        },
      },
      elements: {
        bar: {
          hoverBorderRadius: 25,
          borderSkipped: false,
        },
      },
    };
    const data = { datasets: props.datasets };
    data.labels = props.labels;
    setChart(
      new Chart(document.getElementById(props.chartId), {
        type: `${props.type}`,
        options,
        data,
        plugins: [ChartDataLabels],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.labels]);

  return (
    <MainContainer
      key={props.labels[0]}
      width={props.width}
      height={props.height}
    >
      <HeaderChart>
        <p>{props.title}</p>
        {/* <div>
          <p className="worked">Productos Trabajados</p>
          <p className="rejected">Productos Rechazados</p>
        </div> */}
      </HeaderChart>
      <canvas id={props.chartId}></canvas>
    </MainContainer>
  );
};
export default GeneralChart;
