import React from "react";
import { MainContainer } from "./styles";
import { DateRangePicker } from "rsuite";
import "../../external-styles/calendar.css";
const DateRangeSelection = (props) => {
  const handleChange = (datesPicked) => {
    if (datesPicked) {
      props.setDateFilter({
        filter: "date",
        values: {
          startDate: datesPicked[0],
          endDate: datesPicked[1],
        },
      });
    } else {
      props.setDateFilter();
    }
  };

  return (
    <MainContainer>
      <DateRangePicker
        placeholder="Seleccione un rango de fechas"
        onChange={(evt) => handleChange(evt)}
        style={{ width: "100%" }}
      />
    </MainContainer>
  );
};

export default DateRangeSelection;
