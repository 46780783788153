import styled from 'styled-components';

export const TextUnderImage = styled.div`
  font-size: 24px;
  font-family:Raleway;
  color: #FBFBFB;
  display: flex;
  line-height: 36px;
  justify-content:center;
  align-items: center;

  > p {
    text-align:center;
    width:90%;
    max-width: 418px;
    margin: 0;
  }
`
export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;

  > img{
    margin: 1.3%;
  }
`
export const MainContainer = styled.div`
  height: 100%;
  width:100%;
  display:flex;
`
export const GraphicPanel = styled.div`
  width:50%;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 700px;
    min-height: 700px;

    figure {
      display:flex;
      align-items:center;
      justify-content: center;
      width:100%;
      margin:0;
    }
  }


  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.887706) 49.27%, rgba(255, 255, 255, 0) 100%), #D43594;
`
export const FormPanel = styled.div`
  width:50%;
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginLabel{
    width:92%;
    display:flex;
    justify-content: flex-end;
    color:#B12D84;
    font-family: Avenir Next;
    :hover{
      cursor:pointer;
    }
  }


  > figure {
    width:100%;
    margin:0;
    display:flex;
    justify-content:center;
    align-items: flex-start;

    > img{
      height: 62.14px;
      width:63.8%;
    }
  }
`
export const FormContainer = styled.form`
  width: 460px;
  height:80%;
  margin: 0 auto;
  font-family: Raleway;

  h3 {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #503D66;

    & + * {
      margin-top: 29px;
    }
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #707070;
  }

  input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    &::placeholder {
      color: #D4D1D7;
    }
  }

  >p:last-child{
    margin-top:0.6%;
  }
`
export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 14px;
  };
  .checkboxLabel {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #503D66;

    :hover{
      cursor:pointer
    }
  }
  p{
    margin-bottom: 10px;
    width:100%;
  }

  .forgotten-pass {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin: 0;
  }

  input{
    height:30px;
    width:100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 11px;
    font-family: Lato;
  }

  label {
    font-size:11px;
    color:red;
    font-weight: 700;
  }
`
export const LongItem = styled.div`
  width: 100%;

  p{
    margin-bottom: 10px;
    width:100%;
  }

  input{
    height:30px;
    width:100%;
    border:0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 11px;
    font-family: Lato;

    ::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
    }
    ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
    }
  }
  select{
    width: 100%;
    height: 30px;
  }
  label {
    font-size:11px;
    color:red;
    font-weight: 700;
  }
  .resendTrue {
    color: green;
  }
`
export const PhoneSelect = styled.div`
  width: 10.7%;
  select{
    height:30px;
    width:100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
  }
`
export const PhoneNumberInput = styled.div`
  width: 83%;
  input{
    height:30px;
    width:100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Lato;
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .changeEmail{
    width:100%;
    text-align: center;
    font-size: 13px;
    margin-top:2%;
    font-weight: bold;
    color: #B12D84;
    :hover{
      cursor:pointer;
    }
  }
  .registrationLabel{
      width:100%;
      display:flex;
      justify-content: flex-end;
      font-family: Avenir Next;
      margin-top: 3%;
      color: #B12D84;
      :hover{
        cursor:pointer;
      }
    }

  .row{ 
    display: flex; 
    flex-direction: row;

    &:nth-child(2) {
      font-weight: 500;
      margin: 0;
    }
  }

  &.privacy {
    justify-content: initial;
  }

  &:nth-child(3) {
    margin-top: 23px;
  }

  & + .passwod-strength {
    margin-top: 26px;

    & + .privacy {
      margin-top: 49px; 
    }
  }

  &.privacy {
    align-items: initial;

    & + .privacy {
      margin-top: 12px;
    }
  }

  &.sign-in {
    justify-content: flex-end;
  }

  a {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #503D66;

    span {
      color: #E33AA9;
    }
  }

  & + * {
    margin-top: 20px;
  }
`

export const Button = styled.button`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  background: #B12D84;
  border-radius: 30px;
  border: none;
  width: 158.54px;
  height: 43px;
  color: white;
  :hover{
    cursor:pointer;
  }
`

export const RowButton = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-end;
  margin-top: 44px;
  margin-bottom: 2%;

  & + * {
    margin-top: 43px;
  }
`

export const ConfirmationRowButton = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 2%;
  `
export const StepsRow = styled.div`
  display:flex;
  width: 100%;
`

export const StepDiv = styled.div`
  width: 100%;
  height: 8px;
  background-color:${props => {
    if (props.pasoActual > props.paso) {
      return '#71DE56';
    } else if (props.pasoActual === props.paso) {
      return '#C4C4C4'
    }
    else {
      return '#E2E2E2'
    }
  }};
  border: 0.5px solid #E2E2E2;
`
export const ItemPaso = styled.div`
  width:100%;
  p{
    margin:0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
  }
`
//Styled-Components passwordCreation
export const Terms = styled.div`
  font-size:13px;
  span {
    font-weight: bold;
  }
  div {
    opacity :80%;
    line-height: 24px;
  }
  .verificationCode {
    color: #e451ac;
    font-size: 11px;
    text-decoration: underline #e451ac;
    cursor: pointer;
  }
`
export const PasswordStrenght = styled.div`
  height: 8px;
  width:100%;
  background-color: ${props => {
    switch (props.passwordStrength) {
      case 0:
        return '#F0F0F0'
      case 1:
        if (props.level === 1) {
          return '#E67432'
        }
        else {
          return '#F0F0F0'
        }
      case 2:
        if (props.level <= 2) {
          return '#EDD34D'
        }
        else {
          return '#F0F0F0'
        }
      case 3:
        if (props.level <= 3) {
          return '#71DE56'
        }
        else {
          return '#F0F0F0'
        }
      case 4:
        if (props.level <= 4) {
          return '#71DE56'
        }
        else {
          return '#F0F0F0'
        }
    }
  }};
`
export const ItemPasswordStrength = styled.div`
  width: 24%;
  position: relative;

  p {
    margin-top: 1.5%;
    font-weight: 700;
    display:flex;
    justify-content:flex-end;
    font-size:11px;
    position: absolute;
    right: 0;
    top: 100%;
    color: ${props => {
    switch (props.paso) {
      case 1:
        return '#E67432'
      case 2:
        return '#EDD34D'
      case 3:
        return '#71DE56'
      case 4:
        return '#71DE56'
    }
  }};
  }
`
export const ConfirmationTitle = styled.div`
 font-size:15px;
 width:100%;
 margin-top:4%;
`