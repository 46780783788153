import React, { useState, useEffect, useCallback } from "react";
import { getPaymentStatus } from "../../_utils/helper";
import { isElementClicked } from "../../_utils/helper";
import ReactImageFallback from "react-image-fallback";
import defaultProfile from "../../assets/defaultImages/defaultProfile.png";
import spinner from "../../assets/defaultImages/Spinner.gif";
import {
  MainContainer,
  Option,
  OptionContainer,
  OptionTextContainer,
} from "./styles";

/**
 *
 * @param {Object} item - Order/Product/Task which needs a payment update
 * @param {string} item.key - value to identify the item clicked when handling listener events
 * @param {Int} item.orderId - orderId from the item to modify
 * @param {string} item.payment - default payment status from the item
 * @param {function} item.updatePayment - function which updates the payment of the item(s)
 */
const PaymentModal = ({ item }) => {
  const optionsContainer = ["Pagado", "Pendiente"];
  const [modalOpen, setModalOpen] = useState(false);

  const clickOutsideToClose = useCallback((evt) => {
    const currentDateClicked = ["dated", "pending"].includes(evt.target.id);

    if (!currentDateClicked && !isElementClicked(evt, item.key)) {
      document.removeEventListener("click", clickOutsideToClose);
      setModalOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener("click", clickOutsideToClose);
    }
  }, [modalOpen, clickOutsideToClose]);
  return (
    <MainContainer
      id={item.key}
      onClick={(evt) => {
        setModalOpen(true);
      }}
    >
      <ReactImageFallback
        src={getPaymentStatus(item.payment)}
        fallbackImage={defaultProfile}
        initialImage={spinner}
        className={"imgFallback orders"}
      />
      {
        <OptionContainer show={modalOpen}>
          {modalOpen &&
            optionsContainer.map((option) => (
              <Option
                id="option"
                onClick={(evt) => {
                  evt.stopPropagation();
                  item.updatePayment(item.orderId, option.toLowerCase());
                  setModalOpen(false);
                }}
              >
                <ReactImageFallback
                  src={getPaymentStatus(option)}
                  fallbackImage={defaultProfile}
                  initialImage={spinner}
                />
                <OptionTextContainer>{option}</OptionTextContainer>
              </Option>
            ))}
        </OptionContainer>
      }
    </MainContainer>
  );
};

export default PaymentModal;
