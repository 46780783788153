import { useState, useRef } from "react";
import axios from "axios";
import { getImage } from "../../../../_utils/helper";

import { TableCell, PopUpContainer } from "./styles";
import { ImagesPopup } from "./imagesPopup";

import saveIcon from "../../../../assets/IconComponents/multipleEditionIcons/saveIcon.svg";
import hoverButton from "../../../../assets/IconComponents/multipleEditionIcons/hoverButton.svg";
import activePopUp from "../../../../assets/IconComponents/multipleEditionIcons/activePopUp.svg";
import defaultUpdate from "../../../../assets/defaultImages/defaultUpdate.png";
import errorModal from "../../../../assets/IconComponents/errorModal.svg";
import spinner from "../../../../assets/defaultImages/Spinner.gif";
import { useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { UploadImagemodal } from "../uploadImageModal";
import GenericModal from "../../../../components/GenericModal/index";

export const ImageCell = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState({ src: "" });
  const [showPopup, setShowPopup] = useState(false);
  const image = props.value;
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileSelected, setFileSelected] = useState({ src: "" });
  const [valuesSelected, setValuesSelected] = useState({
    image_type: +props.value?.value?.image_type,
    packing_type: +props.value?.value?.packing_type,
  });
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [errorData, setErrorData] = useState({});
  const [showError, setShowError] = useState(false);

  const inputFileChooser = useRef();

  const closeFileChooser = (e) => {
    if (
      !e.target.closest("#table-cell-" + props.rIndex + "-" + props.index) &&
      showInput
    ) {
      window.removeEventListener("click", closeFileChooser, false);
      setShowInput(false);
    }
  };

  useEffect(() => {
    if (!showUploadModal) {
      if (image?.value?.src) {
        setValue(getImage(image?.value, 28, 28));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUploadModal]);

  useEffect(() => {
    if (showInput) {
      window.addEventListener("click", closeFileChooser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput]);

  const packageOptions = JSON.parse(
    sessionStorage.getItem("packagingType")
  ).map((e) => ({
    name: `${e.name} - ${e.deliverable_value}`,
    value: e.id,
  }));

  const imagenOptions = JSON.parse(sessionStorage.getItem("imageType")).map(
    (e) => ({
      name: `${e.name}`,
      value: e.id,
    })
  );

  const onLoad = () => {
    const img = new Image();
    img.src = fileSelected.src;
    setFileSelected({
      ...fileSelected,
      width: img.width,
      height: img.height,
    });
  };

  const updateImage = async () => {
    try {
      let allValid = true;
      if (props.articlesSelected.length > 0) {
        const arrayPromises = [];
        props.articlesSelected.forEach((article) => {
          const isValid =
            props.images[article.article.id_article].images[value.image_id]
              .value;
          if (isValid) {
            if (
              user.id_user === article.article.id_images_especialist ||
              user.id_user === article.article.id_images_facilitator ||
              user.id_user === article.article.id_auditor ||
              user.id_role === 1
            ) {
              arrayPromises.push(
                axios.put(
                  `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${article.version}`,
                  {
                    articleId: article.article.id_article,
                    orderId: article.article.id_order,
                    updateImages: [
                      {
                        id: isValid.id,
                        image_id: value.image_id,
                        packing_type: valuesSelected.packing_type,
                        image_type: valuesSelected.image_type,
                        version: article.version,
                        width: value.width,
                        height: value.height,
                        ext: value.ext,
                        uuid: value.uuid,
                        srcDB: value.srcDB,
                        src: value.src,
                      },
                    ],
                  },
                  {
                    headers: {
                      Authorization: sessionStorage.getItem("jwt"),
                    },
                  }
                )
              );
            } else {
              allValid = false;
            }
          }
        });
        await Promise.all(arrayPromises);
        if (allValid) {
          window.location.reload();
        } else {
          setErrorData({
            message: "Parece que no fuiste asignado a todos los artículos",
            detail:
              "Solo se actualizaron aquellos articulos a los que fuiste asignado",
            icon: errorModal,
          });
          setShowError(true);
        }
      } else {
        const article = JSON.parse(
          sessionStorage.getItem("multipleEditionList")
        )[props.rIndex];
        if (
          user.id_user === article.article.id_images_especialist ||
          user.id_user === article.article.id_images_facilitator ||
          user.id_user === article.article.id_auditor ||
          user.id_role === 1
        ) {
          await axios.put(
            `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${article.version}`,
            {
              articleId: article.article.id_article,
              orderId: article.article.id_order,
              updateImages: [
                {
                  id: value.id,
                  image_id: value.image_id,
                  packing_type: valuesSelected.packing_type,
                  image_type: valuesSelected.image_type,
                  version: article.version,
                  width: value.width,
                  height: value.height,
                  ext: value.ext,
                  uuid: value.uuid,
                  srcDB: value.srcDB,
                  src: value.src,
                },
              ],
            },
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            }
          );
        } else {
          allValid = false;
        }
        if (allValid) {
          setShowPopup(!showPopup);
        } else {
          setErrorData({
            message: "Parece que no fuiste asignado al artículo",
            detail: "",
            icon: errorModal,
          });
          setShowError(true);
        }
      }
    } catch (err) {
      console.log(err);
      alert("Ups... ocurrió un error, intenta nuevamente");
    }
  };

  return (
    <>
      <TableCell
        key={"" + props.index}
        id={"table-cell-" + props.rIndex + "-" + props.index}
        required={props.required}
      >
        {image === undefined ? (
          <div className="null-container">
            <p>-</p>
          </div>
        ) : (
          <div className="image-container">
            <ReactImageFallback
              onClick={() => setShowInput(!showInput)}
              src={value.src}
              fallbackImage={defaultUpdate}
              initialImage={spinner}
              alt="img"
              onLoad={onLoad}
            />
          </div>
        )}
        {showInput && (
          <div className="input-container">
            <input
              type="file"
              ref={inputFileChooser}
              placeholder="Guardar archivo"
              id={"dinamic-cell-input-" + props.index}
              accept="image/jpg,image/png"
              onChange={(e) => {
                setShowInput(false);
                setShowUploadModal(true);
                const file = e.target.files[0];
                if (file) {
                  var oFReader = new FileReader();
                  oFReader.fileName = file.name;
                  oFReader.readAsDataURL(file);
                  oFReader.onload = function (oFREvent) {
                    const img = new Image();
                    img.src = oFREvent.target.result;
                    setFileSelected({
                      ...value,
                      src: oFREvent.target.result,
                      name: oFREvent.target.fileName,
                      uuid: value.uuid,
                      width: img.width,
                      height: img.height,
                    });
                    setValue({
                      src: oFREvent.target.result,
                      name: oFREvent.target.fileName,
                    });
                  };
                }
              }}
            />
            <label htmlFor={"dinamic-cell-input-" + props.index}>
              Guardar archivo
            </label>
            <div className="save-button">
              <button
                onClick={() => {
                  // setShowInput(false);
                }}
              >
                <img src={saveIcon} alt="guardar" />
              </button>
            </div>
          </div>
        )}
        <div className="show-popup-button">
          <button
            onClick={() => {
              setShowPopup(!showPopup);
            }}
          >
            <img src={!showPopup ? hoverButton : activePopUp} alt="" />
          </button>
        </div>
        {showPopup && (
          <PopUpContainer position={"absolute"} border={true}>
            <ImagesPopup
              title={"Tipo de empaque *"}
              packageOptions={packageOptions}
              setValuesSelected={(value) =>
                setValuesSelected({ ...valuesSelected, packing_type: value })
              }
              defaultValue={valuesSelected.packing_type}
            />
            <ImagesPopup
              title={"Tipo de imagen *"}
              packageOptions={imagenOptions}
              setValuesSelected={(value) =>
                setValuesSelected({ ...valuesSelected, image_type: value })
              }
              defaultValue={valuesSelected.image_type}
            />
            <div className="save-button">
              <button
                onClick={() => {
                  updateImage();
                  // setShowInput(false);
                }}
              >
                <img src={saveIcon} alt="guardar" />
              </button>
            </div>
          </PopUpContainer>
        )}
      </TableCell>

      {showUploadModal && (
        <UploadImagemodal
          setModalImage={(value) => {
            setValue({ src: "" });
            setShowUploadModal(value);
          }}
          img={fileSelected}
          articlesSelected={props.articlesSelected}
          rIndex={props.rIndex}
          imageId={props.imageId}
        />
      )}
      {showError && (
        <GenericModal
          close={() => {
            setShowError(false);
          }}
          message={errorData.message}
          detail={errorData.detail}
          img={errorData.icon}
          textArea={false}
          button1={{
            name: "Regresar",
            action: () => window.location.reload(),
          }}
        />
      )}
    </>
  );
};
