import { FilterInput } from "./styles";
import { FilterOption } from "../filterOption";
import searchIcon from "../iconsFolder/searchIcon.svg";
import { useEffect } from "react";
import Loading from "../../loading/index";

export const FiltersInput = (props) => {
  const filters = props.filtersOptions;
  function copy(obj) {
    let result;
    if (obj instanceof Array) {
      result = [...obj];
    } else if (typeof obj === "object") {
      result = { ...obj };
    } else {
      return obj;
    }
    for (let prop of Reflect.ownKeys(result)) {
      result[prop] = copy(result[prop]);
    }
    return result;
  }

  useEffect(() => {
    const inputElement = document.getElementById("input-search");
    // paste listener
    if (inputElement) {
      inputElement.addEventListener("paste", function (event) {
        let filtersObject = copy(props.globalFilters);
        const pastedText = event.clipboardData.getData("Text");
        event.preventDefault();
        if (pastedText.length >= 5) {
          let string = "";
          const split = pastedText.split("");
          split.forEach((e) => {
            string +=
              e.charCodeAt(0) === 13 || e.charCodeAt(0) === 10 ? "," : e.trim();
          });
          inputElement.value += string;
          let filter = {
            filter: "upc",
            values: string
              .split(",")
              // eslint-disable-next-line array-callback-return
              .filter((e) => {
                if (e.trim().length > 0) return `'${e}'`;
              }),
          };
          props.setKeyword(inputElement.value);
          filtersObject["UPC"] = filter;
          props.setGlobalFilters(filtersObject);
        }
      });

      // key down listener ("enter")
      inputElement.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          let filtersObject = copy(props.globalFilters);
          if (inputElement.value.length === 0) {
            props.setKeyword("");
            delete filtersObject["UPC"];
            props.setGlobalFilters(filtersObject);
          }
          props.setKeyword(inputElement.value);
          event.preventDefault();
          const split = inputElement.value.split(",");
          let filter = {
            filter: "upc",
            values: split
              // eslint-disable-next-line array-callback-return
              .filter((e) => {
                if (e.trim().length > 0) return `'${e}'`;
              }),
          };

          filtersObject["UPC"] = filter;
          props.setGlobalFilters(filtersObject);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterInput id="input-filter">
      <div className="real-input">
        <img src={searchIcon} alt="search icon" className="search-icon" />
        <input
          type="text"
          autoFocus
          id="input-search"
          placeholder={props.keyWords}
        />
      </div>
      <div className="filters-container">
        <div className="filters">
          {filters?.length ? (
            filters
              ?.filter((f) => props.filters[f.name].length)
              ?.map((filter, index) => (
                <FilterOption
                  key={"filter-option-" + index}
                  label={filter.name}
                  id={"filter" + index}
                  filters={props.filters[filter.name]}
                  filterInputFunct={props.filterInputFunct}
                  setKeyword={props.setKeyword}
                  globalFilters={props.globalFilters}
                  setGlobalFilters={props.setGlobalFilters}
                />
              ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </FilterInput>
  );
};
