import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  overflow: auto;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d1d7;
    border-radius: 3px;
  }
`;

export const LeftContainer = styled.div`
  width: 390px;
  position: relative;
  background: #f4f4f7;
  border-radius: 7px;
  height: 613px;
`;

export const RoleBadge = styled.p`
  padding: 3px 9px;
  background: #603888;
  border-radius: 4px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
  color: #ededed;
  width: fit-content;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const Header = styled.div`
  margin: 40px 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    margin-right: 15px;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #817393;
  }
  #title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #ba0070;
  }
`;

export const MainData = styled.div``;

export const DataItem = styled.div`
  display: flex;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
  padding: 0 30px;

  figure {
    & + * {
      margin-left: 11px;
    }
  }

  .info-container {
    display: flex;
    width: 100%;

    p:first-child {
      width: 117px;
      color: #503d66;

      & + p {
        margin-left: 20px;
        color: #817393;
      }
    }
  }

  & + * {
    margin-top: 12px;
  }

  & + ul {
    margin-top: 24px;
  }
`;

export const Navbar = styled.ul`
  padding: 0;
  width: 100%;
  a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;

    &.active {
      background-color: #f0eef2;
      border: 1px solid #e33aa9;
    }
  }

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #fafafa;
    box-sizing: border-box;
    #leftContainer {
      display: flex;
      align-items: center;
      figure {
        margin: 20px 10px;
        width: 14px;
        height: 14px;
        img {
          width: 100%;
        }
      }
    }
    p {
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503d66;
    }
    figure {
      width: 14px;
      height: 14px;
      img {
        width: 100%;
      }

      &:last-child {
        line-height: 55px;
      }
    }
    #arrow {
      width: 6px;
      height: 10px;
      img {
        width: 100%;
      }
    }
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  margin-right: 30px;
  padding-left: 20px;
  box-sizing: border-box;
`;

export const Alert = styled.div`
  width: 100%;
  height: 50px;
  background: #d43594;
  border-radius: 7px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #f7e3ef;
  }
  figure {
    width: 15px;
    height: 20px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  #close {
    position: absolute;
    top: 16px;
    right: 5px;
  }

  & + * {
    margin-top: 30px;
  }
`;
