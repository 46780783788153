import styled from "styled-components";

export const NotifyMenu = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  position: fixed;
  background: #f9f9fc;
  right: -381px;
  width: 381px;
  box-sizing: border-box;
  padding: 20px;
  height: calc(100% - 100px);
  top: 100px;
  box-shadow: -2px 13px 20px rgba(0, 0, 0, 0.16);
  max-height: 99vh;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  z-index: 10;
  transition: right 0.5s;

  .containerHead {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .icon-close-container {
      height: fit-content;
    }
  }
  .containerText {
    margin: 5px;
    margin-left: 0;
    display: flex;
  }
  .box {
    margin: 5px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
  .text24 {
    font-size: 24px;
    font-family: Raleway;
    color: #867586;
  }
  .text14 {
    font-size: 14px;
    font-family: Raleway;
    color: #867586;
  }
  .hidden {
    display: none;
  }
  .textDate {
    margin-left: auto;
    font-family: Raleway;
    color: #6a5880;
  }
  .containerDate {
    display: flex;
    margin: 20px;
  }

  .notification-container + * {
    margin-top: 10px;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    height: fit-content;
  }

  .notifContainer {
    width: 100%;
    text-align: left;

    & + * {
      margin-top: 3%;
    }
  }

  &.notification-active {
    right: 0;
  }
`;

export const Notification = styled.div`
  border: ${(props) =>
    props.read === 0
      ? "1px solid #E33AA9"
      : "1px solid rgba(149, 147, 173, 0.3)"};
  box-sizing: border-box;
  border-radius: 50px;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .container {
    display: flex;
    align-items: center;
  }
  .image {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .text {
    font-size: 12px;
    font-family: Raleway;
    color: #342b3f;
    margin-left: 10px;
  }
  .containerPercentage {
    background: #ed9a4d;
    border-radius: 848px;
    width: 93.5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textPercentage {
    font-size: 13px;
    font-family: Raleway;
    color: #ffffff;
  }
  :hover {
    cursor: pointer;
  }
`;
