import { TablesContainer } from "./styles";
import { DinamicTable } from "../dinamicTable";
import { useEffect } from "react";
import { useState } from "react";

export const DescriptionsTables = (props) => {
  const descriptions = props.descriptions;
  const tableHeader = props.descriptionsHeader;
  const [tableKeys, setTableKeys] = useState([]);
  const [descriptionsArray, setDescriptionsArray] = useState([]);

  const getKeysArray = (data) => {
    let tempArray = [];
    let dataArray = [];
    data.attributes &&
      Object.values(data.attributes).forEach((attribute) => {
        let tempElement = Object.keys(attribute);
        tempElement.pop();
        tempArray.push(tempElement);
      });

    descriptions.data &&
      Object.values(descriptions.data).forEach((description) =>
        dataArray.push(description.descriptions || {})
      );
    setTableKeys(tempArray);
    setDescriptionsArray(dataArray);
  };

  useEffect(() => {
    getKeysArray(descriptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptions]);

  return (
    <TablesContainer>
      {Object.keys(tableHeader).map((key, index) => (
        <DinamicTable
          scrollClass={props.scrollClass}
          onScroll={props.onScroll}
          key={"dinamic-table" + index}
          tableTitle={index === 0 ? "Descripción" : " "}
          subTitle={key}
          tableHeader={tableHeader[key]}
          className="inter-table"
          tableKeys={tableKeys[index]}
          descriptionsArray={descriptionsArray}
          navSelected={props.navSelected}
          articlesSelected={props.articlesSelected}
          descriptions={descriptions.data}
          updatedDescriptions={props.updatedDescriptions}
          setUpdatedDescriptions={props.setUpdatedDescriptions}
        />
      ))}
    </TablesContainer>
  );
};
