import axios from "axios";
import * as XLSX from "xlsx";
import sucess from "../../../assets/IconComponents/sucessModal.svg";
import errorModal from "../../../assets/IconComponents/errorModal.svg";

export const icons = (key, billClass) => {
  const elements = {
    datasheet: (
      <span
        key="datasheet"
        className={`material-icons small ${billClass && "is-billed"}`}
      >
        &#xf8ee;
      </span>
    ),
    description: (
      <span
        key="description"
        className={`material-icons small ${billClass && "is-billed"}`}
      >
        &#xe873;
      </span>
    ),
    images: (
      <span
        key="images"
        className={`material-icons small ${billClass && "is-billed"}`}
      >
        &#xe3f4;
      </span>
    ),
    translate: (
      <span key="translate" className="material-icons small">
        &#xe8e2;
      </span>
    ),
    build: (
      <span key="build" className="material-icons small">
        &#xe1bd;
      </span>
    ),
    search: <span className="material-icons small">&#xe8b6;</span>,
  };
  return elements[key];
};

export const createProductsToBillingArray = (list = [], setState, filters) => {
  const temp = [];
  list.forEach((item) => {
    const billingProduct = {
      articleId: item.articleId,
      orderId: item.orderId,
      retailerId: item.retailers,
    };
    temp.push(billingProduct);
  });
  setState(temp);
};

export const getProducts = async (upcList) => {
  if (upcList) {
    const upcArray = upcList.split(",");
    if (upcArray.length > 500) {
      const promisesArray = [];
      let upcTempArray = [];
      upcArray.forEach((upc, i = 1) => {
        upcTempArray.push(upc);
        const upcCount = i + 1;
        if (upcCount % 500 === 0 || upcCount === upcArray.length) {
          promisesArray.push(
            axios.get(
              `${process.env.REACT_APP_BILLING}?upcList=${encodeURIComponent(
                upcTempArray.join(",")
              )}`,
              {
                headers: {
                  Authorization: sessionStorage.getItem("jwt"),
                },
              }
            )
          );
          upcTempArray = [];
        }
      });
      const products = [];
      let retailersObject = {};
      const responseArray = await Promise.all(promisesArray);
      responseArray.forEach((response) => {
        const { productsList = [], retailers = {} } =
          JSON.parse(response.data.body).data || {};
        productsList.forEach((prod) => {
          if (products === [] || !objectIsInArray(prod, products))
            products.push(prod);
        });
        retailersObject = { ...retailersObject, ...retailers };
      });
      return { finalList: products, retailers: retailersObject };
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BILLING}?upcList=${encodeURIComponent(
          upcList
        )}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const { productsList = [], retailers = {} } =
        JSON.parse(response.data.body).data || {};

      const finalList = productsList;
      return { finalList, retailers };
    }
  }
};

export const billProducts = async (
  billing,
  productsToBilling,
  filters,
  setLoadingState,
  setShowModal,
  setMessage,
  setImageAlert
) => {
  const { services = [] } = filters;
  try {
    if (services.length) {
      productsToBilling.forEach((product) => {
        product["services"] = services;
      });
    }

    let tempArray = [];
    const promisesArray = [];
    productsToBilling.forEach((prod, i = 1) => {
      const productCounter = i + 1;
      tempArray.push(prod);
      if (
        productCounter % 50 === 0 ||
        productCounter === productsToBilling.length
      ) {
        const objectToString = encodeURIComponent(
          JSON.stringify(tempArray) || []
        );

        promisesArray.push(
          axios.put(
            `${process.env.REACT_APP_UPDATE_BILLING_PRODUCTS}?products=${objectToString}&isBilling=${billing}`,
            {},
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            },
            { timeout: 5000 }
          )
        );
        tempArray = [];
      }
    });

    if (promisesArray.length) await Promise.all(promisesArray);

    setMessage("Productos facturados con éxito");
    setShowModal(true);
    setImageAlert(sucess);
    setLoadingState(false);
  } catch (error) {
    setMessage(error.message);
    setLoadingState(false);
    setImageAlert(errorModal);
    setShowModal(true);
  }
};

const objectIsInArray = (object, array) => {
  if (object && array) {
    const objectkeys = Object.keys(object).join("-");
    array.forEach((item) => {
      const itemsKeys = Object.keys(item).join("-");
      if (objectkeys === itemsKeys) return true;
    });
  }
  return false;
};

export const onLoad = async (evt, upcs = [], billingProducts = []) => {
  const bstr = evt.target.result;
  const wb = XLSX.read(bstr, { type: "binary" });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });
  rows.forEach((row, i) => {
    if (row.length) {
      upcs.push(row[0]);
      const element = { upc: `${row[0]}`, orderId: row[1] };
      if (row[2]) element["retailerId"] = row[2];
      billingProducts.push(element);
    }
  });
  return;
};

export const validateProducts = (list) => {
  let upcList = [];
  let message = "";
  list.forEach((item) => {
    if (!item.articleId)
      upcList.push(`upc ${item.upc} no se encuentra en la OT ${item.orderId}`);
  });
  if (upcList.length) message = upcList.join(", ");
  return message;
};
