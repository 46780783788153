import styled from "styled-components";

export const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0px;
  p {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;

    &.title {
      font-weight: 500;
    }
  }
`;

export const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
  }
  figure {
    & + p {
      margin-left: 13px;
    }
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
  .edit :hover {
    cursor: pointer;
  }
`;

export const AboutMe = styled(Section)`
  box-sizing: border-box;

  .title {
    font-weight: bold;
    color: #503d66;
  }
`;

export const SelectFile = styled.input`
  color: transparent;
  display: block;
  cursor: pointer;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  ::before {
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 14px;

    content: "Subir imagen";
    color: #222;
    display: inline-block;
    padding: 6px 40px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #e33aa9;
  }
  :active {
    outline: 0;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

export const ProfileImage = styled.div`
  .img {
    border-radius: 50%;
    width: 83px;
    height: 83px;
  }
`;

export const ImageContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 85px;

  .containerSelectFile {
    min-width: 200px;
  }
`;
