import { ContainerLoader, Loader } from "./styles";
export default function Loading() {
  return (
    <ContainerLoader>
      <Loader>
        <span className="first"></span>
        <span className="second"></span>
        <span className="third"></span>
      </Loader>
    </ContainerLoader>
  );
}
