import { NotifyMenu, Notification } from "./styles";

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import IconBell from "../../assets/IconComponents/IconBell";
import notifyDefaultIcon from "../../assets/defaultImages/notifyDefaultIcon.png";
import AddIconCancel from "../../assets/IconComponents/IconCancel.svg";

export default function NotifyBar(props) {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Deciembre",
  ];
  let dia = "";

  const [notificationsProp, setNotificationsProp] = useState([]);
  const getNotifications = async () => {
    const notificationsResponse = await axios.get(
      process.env.REACT_APP_NOTIFICATIONS_ENDPOINT,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const notif = JSON.parse(
      notificationsResponse?.data?.body
    )?.data?.reverse();
    setNotificationsProp(notif);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const notificationClicked = async (id, route) => {
    const data = {
      idNotification: id,
    };
    let notifications = notificationsProp;
    const itemNotification = notifications.find((not) => not.id === id);
    const indexNotification = notifications.indexOf(itemNotification);
    const routeValues = route.split("/");

    await axios.put(process.env.REACT_APP_NOTIFICATIONS_ENDPOINT, data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    switch (routeValues[0]) {
      case "reception":
        window.location.href = `/${route}`;
        break;

      case "tasks":
        const values = {
          orderId: routeValues[1],
          articleId: routeValues[2],
        };
        sessionStorage.setItem("taskRedirect", JSON.stringify(values));
        window.location.href = `/tasks`;
        break;
      case "products":
        const valuesAssign = {
          orderId: routeValues[1],
          articleId: routeValues[2],
        };
        sessionStorage.setItem("assignRedirect", JSON.stringify(valuesAssign));
        window.location.href = `/products`;
        break;
      case "DashboardProveedor":
        window.location.href = `/orders`;
        break;
      default:
        break;
    }
    notifications[indexNotification].read = 1;
    props.setNotifications(notifications);
  };

  const notifyMenu = useRef();

  useEffect(() => {
    if (notifyMenu.current) notifyMenu.current.focus();
  }, [notifyMenu]);

  return (
    <NotifyMenu
      ref={notifyMenu}
      id="notification-modal"
      className={props.className}
      role="button"
    >
      <div className="containerHead">
        <div className="containerText">
          <IconBell />
          <div className="box">
            <p className="text24">Notificaciones</p>
            <p className="text14">
              {day} de {months[month]} {year}
            </p>
          </div>
        </div>
        <button className="icon-close-container" id="icon-close-button">
          <img src={AddIconCancel} alt="Cerrar" />
        </button>
      </div>
      {notificationsProp.map((notify, i) => (
        <div
          className="notifContainer"
          onClick={(evt) => notificationClicked(notify.id, notify.route)}
          key={i}
        >
          {dia !== notify.date ? (
            <div className="containerDate">
              <b className="textDate">{notify.date}</b>
            </div>
          ) : null}
          <div className="hidden">{(dia = notify.date)}</div>
          <Notification read={notify.read}>
            <div className="container">
              <img src={notifyDefaultIcon} className="image" alt="" />
              <text className="text">{notify.message}</text>
            </div>
            {notify.Percetage != null ? (
              <div className="containerPercentage">
                <p className="textPercentage">{notify.Percetage}</p>
              </div>
            ) : null}
          </Notification>
        </div>
      ))}
    </NotifyMenu>
  );
}
