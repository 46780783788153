import React, { useState, useEffect } from "react";
import { CustomSelect } from "contentoh-components-library";
import { userTypes } from "./utils/graphsUtils";
import { formatDate, datesOption } from "./utils/utilsGeneral";
import UsersTable from "./utils/UsersTable";
import GeneralChart from "./utils/GeneralChart";
import Loading from "../../components/loading";
import { getGeneralMetrics } from "../../_utils/data";
import { MainContainer, Header, ChartsSection } from "./styles";

const CreatorsMetrics = () => {
  const [userType, setUserType] = useState("textSpecialist");
  const [users, setUsers] = useState({});
  const [activeUsers, setActiveUsers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [rejected, setRejected] = useState({
    datasets: [],
    shortNames: [],
    longNames: [],
    footerValues: [],
  });
  const [datesRange, setDatesRange] = useState({
    startDate: formatDate(new Date()),
    endDate: formatDate(new Date()),
  });
  const [dateOptions] = useState(datesOption(setDatesRange));

  const setData = (users, rol) => {
    if (!users || users.length === 0) return;
    const maxBarThickness = 25;
    const dataWorked = [];
    const dataRejected = [];
    const shortNames = [];
    const longNames = [];
    const footerValues = [];
    //Math.ceil(x / 5) * 5;
    let tempUsers = users
      .filter((f) => f.worked !== 0)
      .sort((a, b) => b.percentage - a.percentage);
    const [status1, status2] = userTypes[rol].rejectedStatus;
    const allValues = [];
    tempUsers.forEach((user) => {
      const { initials, name, sumRejected, worked } = user;
      dataWorked.push(worked);
      dataRejected.push(sumRejected);
      allValues.push(worked, sumRejected);
      longNames.push(name);
      shortNames.push(initials);
      footerValues.push({
        [status1]: user[status1],
        [status2]: user[status2],
        "Total Trabajados": worked,
        "Total Rechazados": sumRejected,
      });
    });
    const datasets = [
      {
        label: "Productos Trabajados",
        maxBarThickness,
        backgroundColor: ["#4CECD6"],
        data: dataWorked,
        datalabels: {
          anchor: "end",
          align: "end",
        },
      },
      {
        label: "Productos Rechazados",
        maxBarThickness,
        backgroundColor: ["#EC4C4C"],
        data: dataRejected,
        datalabels: {
          anchor: "end",
          align: "end",
        },
      },
    ];
    setRejected({ datasets, shortNames, longNames, footerValues });
  };

  useEffect(() => {
    if (datesRange) {
      setIsLoading(true);
      getGeneralMetrics({ query: datesRange }).then((res) => {
        setUsers(res);
        setData(res?.textSpecialist, "textSpecialist");
        setIsLoading(false);
      });
    }
  }, [datesRange]);

  useEffect(() => {
    setData(users[userType], userType);
    setActiveUsers(users[userType]);
  }, [userType, users]);

  const rowClicked = (userId) => {
    setActiveUsers(
      activeUsers.map((e) => ({
        ...e,
        rowClicked: e.id === userId,
      }))
    );
  };

  return (
    <MainContainer>
      <Header>
        <div>
          <CustomSelect
            options={Object.values(userTypes).map(({ name, role }, i) => ({
              id: i + 1,
              name: name,
              function: () => setUserType(role),
            }))}
            selectLabel={userTypes[userType].name}
            customSelectId="rol-select"
            parameterArray={Object.values(userTypes).map((e, i) => ({
              id: i + 1,
              name: e.name,
            }))}
            isSingleSelect
          />
          <CustomSelect
            options={dateOptions}
            selectLabel="Hoy"
            placeHolder="Buscar fecha"
            customSelectId="dates-select"
            parameterArray={datesRange}
            setParameterArray={setDatesRange}
            isSingleSelect
          />
        </div>
      </Header>

      {isLoading ? (
        <Loading />
      ) : (
        <ChartsSection>
          <GeneralChart
            chartId="generalChart"
            type="bar"
            labels={rejected.shortNames}
            longNames={rejected.longNames}
            datasets={rejected.datasets}
            footerValues={rejected.footerValues}
            title="Productos trabajados por colaborador"
          />
        </ChartsSection>
      )}

      <UsersTable
        rowClicked={rowClicked}
        users={activeUsers}
        userType={userType}
        datesRange={datesRange}
      />
    </MainContainer>
  );
};
export default CreatorsMetrics;
