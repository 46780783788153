import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { MainContainer, Header, TitleIcon, BasicData } from "./styles";

// SVG assets
import edit from "../../assets/IconComponents/edit.svg";
import password from "../../assets/IconComponents/password.svg";

export default function ChangePassword(props) {
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [passwordToShort, setPasswordToShort] = useState(false);
  const [emptyOldPassword, setEmptyOldPassword] = useState(true);
  const [invalidOldPassword, setInvalidOldPassword] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [errorAws, setErrorAws] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit = async (e) => {
    setShowErrors(true);
    setInvalidOldPassword(false);
    setInvalidCredentials(false);
    setErrorAws(false);
    setLimitExceeded(false);

    let valid = true;
    e.preventDefault();

    let oldPassword = document.querySelector("#oldPasswordInput").value;
    let newPassword = document.querySelector("#newPasswordInput").value;
    let confirmPassword = document.querySelector("#confirmPasswordInput").value;

    newPassword.length < 8
      ? setPasswordToShort(true)
      : setPasswordToShort(false);
    confirmPassword !== newPassword
      ? setPasswordMatches(false)
      : setPasswordMatches(true);
    oldPassword === "" ? setEmptyOldPassword(true) : setEmptyOldPassword(false);

    passwordToShort && (valid = false);
    !passwordMatches && (valid = false);
    emptyOldPassword && (valid = false);

    let user = await Auth.currentAuthenticatedUser();
    if (valid) {
      try {
        await Auth.changePassword(user, oldPassword, newPassword);
        await Auth.signOut({ global: true });
        sessionStorage.clear();
        props.history.push({
          pathname: "/login",
        });
        window.location.reload();
      } catch (err) {
        console.log(err);
        if (err.code === "InvalidParameterException") {
          setInvalidOldPassword(true);
        } else if (err.code === "NotAuthorizedException") {
          setInvalidCredentials(true);
        } else if (err.code === "LimitExceededException") {
          setLimitExceeded(true);
        } else {
          setErrorAws(true);
        }
      }
    }
  };
  return (
    <MainContainer>
      <Header>
        <div>
          <TitleIcon>
            <img src={password} alt="Section icon" />
          </TitleIcon>
          <p>Cambiar contraseña </p>
        </div>
      </Header>

      <BasicData>
        <div>
          <p className="title">Contraseña actual</p>
          <input
            type="password"
            placeholder="Ingresa tu contraseña actual"
            id="oldPasswordInput"
          ></input>
          {showErrors && invalidCredentials && (
            <label>Contraseña incorrecta</label>
          )}
          {showErrors && invalidOldPassword && (
            <label>Las contraseñas deben ser de minimo 8 caracteres</label>
          )}
          {showErrors && emptyOldPassword && (
            <label>Ingrese su contraseña actual</label>
          )}
          <p>
            ¿Olvidaste tu contraseña?{" "}
            <a href="#forgot_password">Da click aquí</a>
          </p>
        </div>
      </BasicData>

      <BasicData>
        <div>
          <p className="title">Contraseña nueva</p>
          <input
            id="newPasswordInput"
            type="password"
            placeholder="Ingresa la contraseña nueva"
          ></input>
          {showErrors && passwordToShort && (
            <label>La contraseña debe ser minimo de 8 caracteres</label>
          )}
        </div>
        <div>
          <p className="title">Confirmar contraseña</p>
          <input
            id="confirmPasswordInput"
            type="password"
            placeholder="Ingresa la contraseña nueva"
          ></input>
          {showErrors && !passwordMatches && (
            <label>La contraseña no coincide</label>
          )}
        </div>
      </BasicData>

      <BasicData>
        <div className="align_right">
          {showErrors && limitExceeded && (
            <label>Demasiados intentos fallidos, intente más tarde</label>
          )}
          {showErrors && errorAws && (
            <label>Algo ha salido mal, intente más tarde</label>
          )}
          <button onClick={(e) => onSubmit(e)}>Cambiar Contraseña</button>
        </div>
      </BasicData>
    </MainContainer>
  );
}
